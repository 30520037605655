<template>
  <component :is="element" v-if="user.picture"><img :src="user.picture" /></component>
  <component :is="element" v-else color="primary" class="white--text text-h6 justify-center">{{userInitial}}</component>
</template>

<script>
import {VAvatar, VListItemAvatar} from "vuetify/lib"

export default {
  name: "UserAvatar",
  components: {VAvatar, VListItemAvatar},

  props: {
    user: Object,
    list: Boolean
  },

  computed: {
    element() {
      if (this.list)
        return "v-list-item-avatar";
      return "v-avatar";
    },

    userInitial() {
      return (this.user.name || this.user.email)[0].toUpperCase();
    }
  }
}
</script>

<style>

</style>