<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <h2 class="text-h4">Dashboard</h2>
      <v-btn text :loading="reportNotLoaded" @click="copyReportToClipboard" :color="copySuccess ? 'success' : 'default'">
        <template v-if="!copySuccess">
          <v-icon left>mdi-content-copy</v-icon>
          Copy Report
        </template>
        <template v-else>
          <v-icon left>mdi-check</v-icon>
          Copied!
        </template>
      </v-btn>
    </div>
    
    <!-- Controls -->
    <div class="d-flex justify-space-between align-center">
      <v-text-field
        v-model="filter"
        prepend-inner-icon="mdi-magnify"
        label="Filter Profiles"
        single-line
        hide-details
        dense
        outlined
        class="shrink"
      ></v-text-field>

      <v-btn-toggle v-model="isListView" mandatory group dense>
        <v-btn class="no-text" :value="false">
          <v-icon>mdi-view-grid</v-icon>
        </v-btn>
        <v-btn class="no-text" :value="true">
          <v-icon>mdi-view-list</v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-divider class="mb-6 mt-2" />

    <!-- Grid View -->
    <template v-if="!isListView">
      <v-row v-show="filteredProfileIds.size">
        <v-col v-for="(deliverable, index) in deliverables" v-show="filteredProfileIds.has(deliverable.profile_id)" :key="deliverable.id" cols="6" sm="4" md="3">
          <v-card outlined height="100%" class="d-flex flex-column">
            <div class="thumbnail" @click="showPreview(index)">
              <deliverable-thumbnail :deliverable="deliverable" />
              <div class="thumbnail-info d-flex justify-space-between align-center">
                <span class="text-caption"></span>
                <span @click.stop>
                  <v-btn dark icon @click="goToContent(deliverable)">
                    <v-icon color="white" style="background-color: #00000090; border-radius: 4px;">mdi-open-in-new</v-icon>
                  </v-btn>
                </span>
              </div>
            </div>

            <!-- Card Body -->
            <v-card-text class="d-flex flex-column justify-space-between" style="flex-grow: 1;">
              <div>
                <b style="color: black">{{ deliverable.profile_name || deliverable.profile_id }}</b><br />
                <b>Last Capture: </b>{{ $dayjs(deliverable.sync_time).fromNow() }}<br />
              </div>
              <!-- Monitoring Chips -->
              <monitoring-chips class="mt-3" :profileId="deliverable.profile_id" :profileName="deliverable.profile_name" />
            </v-card-text>
            
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="!deliverables.length && reportNotLoaded">
        <v-col v-for="index in 20" :key="index" cols="6" sm="4" md="3">
          <v-skeleton-loader type="card, list-item@2"></v-skeleton-loader>
        </v-col>
      </v-row>

      <p v-else-if="!filteredProfileIds.size" class="text-center">No profiles found</p>
    </template>

    <!-- List View -->
    <template v-else>
      <v-card>
        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th>Profile</th>
                <th>Status</th>
                <th>Last Capture</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="deliverable in deliverables" v-show="filteredProfileIds.has(deliverable.profile_id)" :key="deliverable.id">
                <td>{{ deliverable.profile_name || deliverable.profile_id }}</td>
                <td>
                  <monitoring-chips :profileId="deliverable.profile_id" :profileName="deliverable.profile_name" />
                </td>
                <td>{{ $dayjs(deliverable.sync_time).fromNow() }}</td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-skeleton-loader v-if="!filteredProfileIds.size && reportNotLoaded" type="table-tbody" :types="{'table-row': 'table-cell@3'}"></v-skeleton-loader>
          
          <p v-else-if="!filteredProfileIds.size" class="text-center mt-6">No profiles found</p>
        </v-card-text>
      </v-card>
    </template>
    
    <!-- Deliverable Preview Dialog -->
    <deliverable-preview-dialog
      ref="preview"
      nav
      :previousDisabled="previewItemIndex === 0"
      :nextDisabled="previewItemIndex === deliverables.length - 1"
      @previous="showPreview(previewItemIndex - 1)"
      @next="showPreview(previewItemIndex + 1)"
    />

  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import DeliverableThumbnail from '../components/DeliverableThumbnail.vue';
import DeliverablePreviewDialog from '../components/DeliverablePreviewDialog.vue';
import MonitoringChips from '../components/MonitoringChips.vue';

export default {
  name: 'Dashboard',
  components: {
    DeliverableThumbnail,
    DeliverablePreviewDialog,
    MonitoringChips
  },

  data() {
    const formattedDate = `Perm Installs Report ${dayjs().format('dddd, MMMM D, YYYY (h:mmA)')} \n----------------------------------------\n`;
    return {
      deliverables: [],
      report: formattedDate,
      reportNotLoaded: true,
      copySuccess: false,
      copySuccessTimer: null,
      previewItemIndex: null,
      isListView: false,
      filter: '',
    };
  },

  computed: {
    filteredProfileIds() {
      if (!this.filter) {
        return new Set(this.deliverables.map(d => d.profile_id));
      }

      let lowerCaseFilter = this.filter.toLowerCase();
      let deliverables = this.deliverables.filter(d =>
        d.profile_id.toLowerCase().includes(lowerCaseFilter) ||
        (d.profile_name && d.profile_name.toLowerCase().includes(lowerCaseFilter))
      );

      return new Set(deliverables.map(d => d.profile_id));
    }
  },

  async created() {
    let profiles = await this.$root.getProfiles();
    profiles = profiles.filter(profile => profile.id.startsWith('install.'));
    profiles = profiles.sort((a, b) => a.id.localeCompare(b.id)); // sort alphabetically

    // fetch most recent deliverable from each install
    for (let i = 0; i < profiles.length; i++) {
      await this.loadDeliverable(profiles[i]);
    }
    this.reportNotLoaded = false;
  },

  methods: {
    async loadDeliverable(profile) {
      let response = await this.$api.get('/deliverables', {
        params: {
          profile_id: profile.id,
          page_size: 1,
          order_by: 'desc'
        }
      });
      let deliverable = response.data.items[0];
      if (!deliverable) return; // if profile has no deliverable, break.
      if (!deliverable.thumbnail) return; // if deliverable has no content, break.
      deliverable.monitoring = []; // initialize monitoring.
      deliverable.profile_name = profile.name;
      this.deliverables.push(deliverable);

      // gathering data for the report
      this.report += `${profile.id}`;
      this.report += ` - Last Capture ${this.$dayjs(deliverable.create_time).fromNow()}\n`; // E.g. 7 Days Ago
    },

    async copyReportToClipboard() {
      try {
        await navigator.clipboard.writeText(this.report);
        this.copySuccess = true;
        clearTimeout(this.copySuccessTimer);
        this.copySuccessTimer = setTimeout(() => { this.copySuccess = false }, 2000);
      } catch (err) {
        this.copySuccess = false;
        console.error('Failed to copy text: ', err);
      }
    },

    showPreview(index) {
      this.previewItemIndex = index;
      this.$refs.preview.open(this.deliverables[index]);
    },

    goToContent(deliverable) {
      this.$root.profileId = deliverable.profile_id;
      this.$router.push({ name: 'content' });
    },
  }
};
</script>

<style scoped>
.thumbnail {
  position: relative;
  width: 100%;
  aspect-ratio: 4 / 6;
  object-fit: cover;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;
}

.thumbnail-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #000;
  background: linear-gradient(rgba(75, 74, 74, 0), rgba(162, 162, 162, 0.43));
  opacity: 0;
  transition: opacity 0.1s;
}

.thumbnail:hover .thumbnail-info {
  opacity: 1;
}
</style>
