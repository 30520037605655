<template>
  <v-menu v-model="showMenu" :close-on-content-click="false" offset-y nudge-bottom="4" :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text class="text-transform-none text-subtitle-1" v-on="on" v-bind="attrs" :disabled="disabled">
        <template v-if="value">{{ value }}</template>
        <template v-else>Select a profile</template>
        <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-text>
        <v-text-field spellcheck="false" autofocus v-model="search" solo dense prepend-inner-icon="mdi-magnify" clearable
          hide-details @input="filterProfiles" />
        <div v-if="isLoading" class="mt-4 text-center">
          <v-progress-circular indeterminate width="2" size="24" />
        </div>
        <v-list v-else-if="filteredProfiles.length">
          <div class="profile-list" ref="profileList">
            <v-list-item v-for="profile in filteredProfiles" :key="profile" link color="primary"
              :input-value="profile === value" @click="selectProfile(profile)">
              <v-list-item-title>{{ profile }}</v-list-item-title>
            </v-list-item>
          </div>
        </v-list>
        <div v-else class="mt-4 pl-4">No profiles found</div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "ProfilePicker",

  props: {
    value: String,
    disabled: Boolean,
  },

  data: () => ({
    showMenu: false,
    profiles: [],
    filteredProfiles: [],
    isLoading: false,
    search: "",
  }),

  async created() {
    this.isLoading = true;
    try {
      this.profiles = await this.$root.getProfilesFromBucket();
    } finally {
      this.isLoading = false;
      this.filterProfiles();
    }
  },

  methods: {
    selectProfile(profile) {
      this.$emit("input", profile);
      this.showMenu = false;
    },

    filterProfiles() {
      const search = this.search.toLowerCase().trim();
      this.filteredProfiles = this.profiles.filter((profile) =>
        profile.toLowerCase().includes(search)
      );
    },
  },
};
</script>

<style scoped>
.text-transform-none {
  text-transform: none;
}

.profile-list {
  max-height: 400px;
  overflow-y: scroll;
}
</style>
