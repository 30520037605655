<template>
  <v-container class="px-lg-12">

    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <h2 class="text-h4">Profile Templates</h2>
    </div>

    <!-- Card -->
    <v-row>
      <v-col>
        <v-card>
          <v-card-header>
            <p>Templates</p>
          </v-card-header>
          <v-card-text>
            <v-row v-if="loading" justify="center" align="center" style="height: 100%;">
              <v-col cols="auto">
                <v-progress-circular indeterminate width="2" size="24"></v-progress-circular>
              </v-col>
            </v-row>
            <v-simple-table v-else-if="profiles.length > 0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Profile</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in profiles" :key="index">
                    <td>{{ row }}</td>
                    <td>
                      <v-btn color="primary" text @click="createProfile(row)">
                        Create Profile
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div v-else-if="!loading && profiles.length === 0" class="text-center">
              No data to display.
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ProfileTable",

  data() {
    return {
      profiles: [],
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    try {
      this.profiles = await this.$root.getProfilesFromBucket();
    } finally {
      this.loading = false;
    }
  },

  methods: {
    createProfile(profileName) {
      this.$router.push(`/profile-template/${profileName}/create`);
    },
  },
};
</script>
