<template>
  <v-container class="px-lg-12">

    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <h2 class="text-h5">
        <router-link :to="{name: 'profile-admin'}">Profiles</router-link>
        <v-icon class="mx-2">mdi-chevron-right</v-icon>
        <span>{{profileId}}</span>
      </h2>
      <v-btn v-if="canEdit" text exact :to="{name: 'profile-edit'}">
        <v-icon left>mdi-pencil</v-icon>
        Edit
      </v-btn>
    </div>

    <status-alert/>

    <!-- Details -->
    <v-card>
      <v-card-header>
        <v-card-title class="pa-0">Details</v-card-title>
      </v-card-header>
      <v-card-text>
        <async-state :promise="promise">
          <template v-slot:default>
          <v-row class="row-divided">
            <v-col cols="6">
              <label-value label="ID" :value="profile.id" />
              <label-value label="Name" :value="profile.name" />
            </v-col>
            <v-col cols="6">
              <label-value label="Created Time" :value="$formatDateTime(profile.create_time)" />
            </v-col>
          </v-row>
          </template>
        </async-state>
      </v-card-text>
    </v-card>

    <!-- Schemas -->
    <v-card class="mt-6">
      <v-card-header>
        <v-card-title class="pa-0 d-flex justify-space-between">
          <span>
            Schemas
          </span>
          <v-btn-toggle v-model="schemaType" group dense mandatory>
            <v-btn value="submission" class="text-none">Submission</v-btn>
            <v-btn value="deliverable" class="text-none">Deliverable</v-btn>
          </v-btn-toggle>
        </v-card-title>
      </v-card-header>
      <v-card-text>
        <async-state :promise="promise" :has-data="Boolean(attributesLength)">
          <template #default>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Attribute</th>
                  <th>Value</th>
                  <th>Personal</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(attr, i) in profile.schemas[`${schemaType}`].attributes" :key="i">
                  <td>{{attr.id}}</td>
                  <td>{{attr.type}}</td>
                  <td>{{attr.personal}}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </template>
        </async-state>
      </v-card-text>
    </v-card>

    <!-- Retention Policy -->
    <v-card class="mt-6">
      <v-card-header>
        <v-card-title class="pa-0">
          <span>Retention Policy</span>
        </v-card-title>
      </v-card-header>
      <v-card-text>
        <async-state :promise="promise" :has-data="Boolean(rententionPolicyLength)">
          <template #default>
            <v-row class="row-divided">
              <v-col sm="6" cols="12">
                <label-value label="Enabled">
                  <v-chip outlined small :color="profile.retention_policy.enabled ? 'success' : 'error'">
                    <v-icon small left>{{profile.retention_policy.enabled ? 'mdi-check' : 'mdi-close'}}</v-icon>
                  {{profile.retention_policy.enabled ? 'Enabled' : 'Disabled'}}
                </v-chip>
              </label-value>
              <label-value label="Duration" :value="profile.retention_policy.duration_days.toString() + ` Days`" />
              </v-col>
              <v-col sm="6" cols="12">
                <v-checkbox disabled style="margin: 0;"
                  v-model="profile.retention_policy.erase_submissions"
                  label="Erase Submissions"
                ></v-checkbox>
                <v-checkbox disabled style="margin: 0;"
                  v-model="profile.retention_policy.erase_deliverables"
                  label="Erase Deliverables"
                ></v-checkbox>
                <v-checkbox disabled style="margin: 0;"
                  v-model="profile.retention_policy.erase_deliveries"
                  label="Erase Deliveries"
                ></v-checkbox>
              </v-col>
            </v-row>
          </template>
        </async-state>
      </v-card-text>
    </v-card>
    
  </v-container>
</template>

<script>

import AsyncState from "../components/AsyncState"
import LabelValue from "../components/LabelValue"
import StatusAlert from '../components/StatusAlert.vue'

export default {
  name: "ProfileDetail",
  components: {AsyncState, LabelValue, StatusAlert},

  props: {
    profileId: String
  },

  data() {
    return {
      profile: null,
      promise: null,
      schemaType: 'submission'
    };
  },

  created() {
    this.promise = this.load();
  },

  computed: {
    canEdit() {
      return this.$root.permissions.canPerform("update_profile", {profile: this.profileId});
    },
    attributesLength() {
      return this.profile?.schemas[`${this.schemaType}`].attributes.length || 0;
    },
    rententionPolicyLength() {
      return this.profile?.retention_policy ? Object.entries(this.profile.retention_policy).length : 0;
    }
  },

  methods: {
    async load() {
      this.profile = null;
      let response = await this.$api.get("/profiles/" + this.profileId);
      this.profile = response.data;
    }
  }
};
</script>
