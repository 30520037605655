<template>
  <v-container>
    <div>
      <v-row v-if="loading" justify="center" align="center" style="height: 100%;">
        <v-col cols="auto">
          <v-progress-circular indeterminate width="2" size="24"></v-progress-circular>
        </v-col>
      </v-row>

      <template v-if="!loading">
        <!-- Header -->
        <div class="d-flex justify-space-between align-center flex-wrap px-5 mt-4" @mouseenter="infoButton = true" @mouseleave="infoButton = false">
          <div class="d-flex align-center flex-wrap text-h5">
            <router-link :to="{name: 'profiles', params: {profileName: id}}">Customisation</router-link>
            <v-icon class="mx-2">mdi-chevron-right</v-icon>
            {{
              isNewSchedule
              ? "Create Schedule"
              : isDefaultProfile
              ? "Edit Default Configuration"
              : "Edit Schedule"
            }}
            <v-btn @click="alert = !alert" text fab small color="grey" :class="{ 'disappear': !infoButton }" class="mx-1">
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
          </div>
          <div>
            <v-btn text exact :to="{name: 'profiles', params: {profileName: id}}">
              <v-icon left>mdi-close</v-icon>
              Cancel
            </v-btn>
            <v-btn text color="primary" :loading="loading" :disabled="!isValid" @click="buttonAction">
              <v-icon left>mdi-check</v-icon>
              Save
            </v-btn>
          </div>
        </div>

        <!-- Info Alert -->
        <v-alert class="mt-4 mx-4"
          v-model="alert"
          type="info" dismissible
          elevation="2" transition="scale-transition"
        >
          <span v-if="isNewSchedule">On this page you can set up a temporary configuration (or 'schedule') for a booth, overriding the default configuration for a set period of time. Leave a field blank, and the default for that field will be applied. </span>
          <span v-else-if="isDefaultProfile">On this page you can set the default configuration for a booth. This configuration will be overridden when a schedule is active.</span>
          <span v-else>On this page you can edit a temporary configuration for a booth, overriding the default configuration.</span>
        </v-alert>

        <v-card-text>
          <v-row>
            
            <v-col cols="12">
                <v-card v-if="!isDefaultProfile" class="mb-10">

                  <v-card-text >
                    <!-- If Schedule -->
                    <v-row>
                      <v-col cols="6" style="padding-bottom: 0;">
                        <date-time-picker
                        label="Start Date"
                        v-model="selectedSchedule.start_time"
                        :required="true"
                        :preset="isNewSchedule"
                        :clearable="true"
                        ></date-time-picker>
                      </v-col>
                      
                      <v-col cols="6" style="padding-bottom: 0;">
                        <date-time-picker
                        label="End Date"
                        v-model="selectedSchedule.end_time"
                        :required="true"
                        :preset="isNewSchedule"
                        :clearable="true"
                        ></date-time-picker>
                      </v-col>
                    </v-row>
                    
                    <v-row style="margin: -1rem 0 -1.4rem 0;">
                      <v-col style="padding: 0rem;">
                        <v-textarea label="Description" v-model="selectedSchedule.description" auto-grow  
                        rows="1"
                        row-height="24"></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                
                  <!-- Profile Values -->
                  <div v-for="controlGroup in groupedControls" :key="controlGroup.title" class="mb-12">
                  <v-card>
                    <v-card-title class="text-subtitle-2 grey lighten-4">
                      <h2 class="grey--text text--darken-4">{{ controlGroup.title }}</h2>
                    </v-card-title>
                    <v-divider />
                    
                    <v-row no-gutters class="py-4" >  <!-- Split each control group into two columns -->
                      <v-col sm="6" cols="12">
                        <div class="d-flex flex-column px-5" v-for="control in controlGroup.items.slice(0, Math.ceil(controlGroup.items.length / 2))" :key="control.id">
                        <custom-control class="pb-6"
                          :control="control" 
                          :variables="selectedSchedule.variables"
                          :files="selectedSchedule.files"
                          :scheduleIndex="scheduleIndex"
                          @raw-files-changed="handleRawFilesChange" 
                        />
                      </div>
                    </v-col>
                    <v-divider :vertical="true"/>
                    <v-col sm="6" cols="12">
                      <div class="d-flex flex-column px-5"  v-for="control in controlGroup.items.slice(Math.ceil(controlGroup.items.length / 2))" :key="control.id">
                        <custom-control class="pb-6"
                          :control="control" 
                          :variables="selectedSchedule.variables"
                          :files="selectedSchedule.files"
                          :scheduleIndex="scheduleIndex"
                          @raw-files-changed="handleRawFilesChange" 
                        />
                      </div>
                    </v-col>
                  </v-row>

                </v-card>
              </div>

              <div v-if="ungroupedControls.length" class="mb-12">
                <h2 class="mb-4">Ungrouped Controls</h2>

                <div v-for="control in ungroupedControls" :key="control.id" class="mb-10">
                  <custom-control :control="control" :variables="selectedSchedule.variables" :files="selectedSchedule.files"
                    :scheduleIndex="scheduleIndex" @raw-files-changed="handleRawFilesChange" />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-dialog v-model="dialog" persistent max-width="500" v-if="dialog">
            <v-card>
              <v-card-title>Confirmation</v-card-title>
              <v-card-text>
                {{ isDefaultProfile ? 'Are you sure you want to save the default profile values?' : 'Are you sure you want to edit the schedule?' }}
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn color="red darken-1" text @click="dialog = false">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="confirmAction">
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </template>
    </div>
  </v-container>
</template>

<script>

import { VDialog } from 'vuetify/lib';
import CustomControl from '../components/CustomControl.vue';
import DateTimePicker from '../components/DateTimePicker.vue';

export default {
  name: 'ProfileForm',
  components: {
    VDialog,
    CustomControl,
    DateTimePicker
},
  data() {
    return {
      scheduleIndex: null,
      selectedSchedule: null,
      profile: null,
      loading: false,
      dialog: false,
      rawFiles: {},
      alert: false,
      infoButton: false
    };
  },
  computed: {
    isNewSchedule() {
      return this.$route.path.includes("new-schedule");
    },
    isDefaultProfile() {
      return this.$route.path.endsWith("/default");
    },
    groupedControls() {
      return this.profile?.custom_controls?.filter(control => control.type === 'group') || [];
    },
    ungroupedControls() {
      return this.profile?.custom_controls?.filter(control => control.type !== 'group') || [];
    },
    isValid() {
      if (this.isDefaultProfile) {
        return true
      }
      return this.selectedSchedule.start_time && this.selectedSchedule.end_time;
    },
    id() {
      return this.$route.params.profileName || ''
    }
  },
  created() {
    if (this.isNewSchedule) {
      this.scheduleIndex = -1;
    } else {
      this.scheduleIndex = parseInt(this.$route.params.index) - 1;
    }
    this.fetchProfile(this.$route.params.profileName);
  },
  methods: {
    handleRawFilesChange(eventPayload) {
      const { file, fileName } = eventPayload;
      if (file) {
        this.rawFiles[fileName] = file;
      } else {
        this.$delete(this.rawFiles, fileName);
      }
    },
    initializeNewSchedule() {
      this.selectedSchedule = {
        variables: {},
        files: {},
      };
    },
    async fetchProfile(profileName) {
      if (profileName) {
        try {
          this.loading = true;
          this.profile = await this.$root.getProfileFromBucket(profileName);
          if (this.isNewSchedule) {
            this.initializeNewSchedule();
          } else if (this.isDefaultProfile) {
            this.selectedSchedule = {
              variables: { ...this.profile.variables, },
              files: { ...this.profile.files, },
            };
          } else {
            this.selectedSchedule = {
              ...this.profile.schedules[this.scheduleIndex],
              variables: {
                ...this.profile.schedules[this.scheduleIndex].variables,
              },
              files: {
                ...this.profile.schedules[this.scheduleIndex].files,
              },
            };
          }
          this.loading = false;
        } catch (err) {
          console.error('Error fetching profile', err);
          this.loading = false;
        }
      } else {
        this.profile = null;
      }
    },
    buttonAction() {
      if (this.isNewSchedule) {
        this.createSchedule();
      } else {
        this.dialog = true;
      }
    },
    async confirmAction() {
      this.dialog = false;
      this.loading = true;
      if (this.isDefaultProfile) {
        await this.editDefaultProfileValues();
      } else {
        await this.confirmEditSchedule();
      }
      this.loading = false;
    },
    async editDefaultProfileValues() {
      this.selectedSchedule.variables = Object.fromEntries(
        Object.entries(this.selectedSchedule.variables).filter(([, value]) => value !== null && value !== "")
      );

      this.profile.variables = this.selectedSchedule.variables;
      this.profile.files = this.selectedSchedule.files;
      const response = await this.$root.updateProfile(this.profile, this.rawFiles);
      if (response.status === 200) {
        this.$router.push(`/profiles/${this.$route.params.profileName}`);
      }
    },
    async createSchedule() {
      this.loading = true;

      this.selectedSchedule.variables = Object.fromEntries(
        Object.entries(this.selectedSchedule.variables).filter(([, value]) => value !== null && value !== "")
      );
      this.profile.schedules.push(this.selectedSchedule);
      const response = await this.$root.updateProfile(this.profile, this.rawFiles);
      if (response.status === 200) {
        this.$router.push(`/profiles/${this.$route.params.profileName}`);
      }

      this.loading = false;
    },
    async confirmEditSchedule() {
      this.selectedSchedule.variables = Object.fromEntries(
        Object.entries(this.selectedSchedule.variables).filter(([, value]) => value !== null && value !== "")
      );

      this.profile.schedules[this.scheduleIndex] = this.selectedSchedule;

      const response = await this.$root.updateProfile(this.profile, this.rawFiles);

      if (response.status === 200) {
        this.$router.push(`/profiles/${this.$route.params.profileName}`);
      }
    },
  },
};
</script>

<style scoped>

.disappear {
  opacity:0;
}
</style>