<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <h2 class="text-h5">
        <router-link :to="{name: 'deliveries'}">Deliveries</router-link>
        <v-icon class="mx-2">mdi-chevron-right</v-icon>
        <span>{{id}}</span>
      </h2>
    </div>

    <!-- Details -->
    <v-card>
      <v-card-header>
        <v-card-title class="pa-0">Details</v-card-title>
      </v-card-header>
      <v-card-text>
        <async-state :promise="deliveryPromise">
          <template v-slot:default>
          <v-row class="row-divided">
            <v-col cols="4">
              <label-value label="ID" :value="delivery.id" />
              <label-value label="Profile" :value="delivery.profile_id" />
            </v-col>
            <v-col cols="4">
              <label-value label="Status">
                <status-chip :status="delivery.status" small />
              </label-value>
            </v-col>
            <v-col cols="4">
              <label-value label="Created time" :value="$formatDateTime(delivery.create_time)" />
              <label-value label="Synced time" :value="$formatDateTime(delivery.sync_time)" />
            </v-col>
          </v-row>
          </template>
        </async-state>
      </v-card-text>
    </v-card>

    <v-row class="mt-6">
      <v-col cols="12" md="6">
        <!-- Message -->
        <v-card height="100%">
          <v-card-header>
            <v-card-title class="pa-0">Message</v-card-title>
          </v-card-header>
          <v-card-text>
            <async-state :promise="deliveryPromise">
              <template v-slot:default>
                <label-value label="Destination" :value="delivery.destination" />
                <label-value v-if="delivery.template_id" label="Template">
                  <router-link :to="{name: 'template-detail', params: {profileId: delivery.profile_id, id: delivery.template_id}}">
                    {{delivery.template_id}}
                  </router-link>
                </label-value>
                <label-value v-if="delivery.source" label="Source" :value="delivery.source" />
                <label-value v-if="delivery.subject" label="Subject" :value="delivery.subject" />
                <label-value v-if="delivery.deliverable_id" label="Attached Content">
                  <router-link :to="{name: 'content-detail', params: {profileId: delivery.profile_id, id: delivery.deliverable_id}}">
                    {{delivery.deliverable_id}}
                  </router-link>
                </label-value>
              </template>
            </async-state>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <!-- Attributes -->
        <v-card height="100%">
          <v-card-header>
            <v-card-title class="pa-0">
              <span>Attributes</span>
              <span v-if="delivery" class="grey--text ml-2">({{attributesLength}})</span>
            </v-card-title>
          </v-card-header>
          <v-card-text>
            <async-state :promise="deliveryPromise" :has-data="Boolean(attributesLength)">
              <template #default>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Attribute name</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, key) in delivery.attributes" :key="key">
                      <td>{{key}}</td>
                      <td>{{value}}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </template>
            </async-state>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <confirm-dialog ref="confirm" />

  </v-container>
</template>

<script>
import AsyncState from "../components/AsyncState"
import StatusChip from "../components/StatusChip"
import LabelValue from "../components/LabelValue"
import ConfirmDialog from "../components/ConfirmDialog"

export default {
  name: "DeliveryDetail",
  components: {AsyncState, StatusChip, LabelValue, ConfirmDialog},

  props: {
    id: String,
  },

  data() {
    return {
      delivery: null,
      deliveryPromise: null,
    };
  },

  computed: {
    attributesLength() {
      if (!this.delivery)
        return null;
      return Object.keys(this.delivery.attributes).length;
    },

  },

  created() {
    this.deliveryPromise = this.loadDelivery();
  },

  methods: {
    async loadDelivery() {
      this.delivery = null;
      let response = await this.$api.get("/deliveries/" + this.id);
      this.delivery = response.data;
      this.$root.profileId = response.data.profile_id;
    },

  }
};
</script>
