<template>
  <v-menu
    v-if="value && value.length"
    v-model="showMenu"
    :close-on-content-click="false"
    offset-y
    nudge-bottom="4"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        class="text-transform-none text-subtitle-1"
        v-on="on"
        v-bind="attrs"
        :disabled="disabled"
      >
        <span>
          Filters
          <template v-if="enabledFilters.length">({{enabledFilters.length}})</template>
        </span>
        <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-text>
        <filters-list :value="value" @input="$emit('input', $event)" />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import FiltersList from "./FiltersList.vue";
import Filters from "../filters";

export default {
  name: "FiltersPicker",
  components: {FiltersList},

  props: {
    value: Filters,
    disabled: Boolean,
  },

  data: () => ({
    showMenu: false
  }),

  computed: {
    enabledFilters() {
      return this.value.enabled;
    }
  }
};
</script>

<style scoped>
.text-transform-none {
  text-transform: none;
}
</style>