<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <h2 class="text-h5">
        <router-link :to="{name: 'templates'}">Delivery Templates</router-link>
        <v-icon class="mx-2">mdi-chevron-right</v-icon>
        <span>{{id}}</span>
      </h2>
      <div>
        <v-btn v-if="canCreateDelivery" text @click="openEmailTester">
          <v-icon left>mdi-email-arrow-right</v-icon>
          Test
        </v-btn>
        <v-btn v-if="canEdit" text :to="{name: 'template-edit'}">
          <v-icon left>mdi-pencil</v-icon>
          Edit
        </v-btn>
        <v-btn v-if="canDelete" text color="error" @click="deleteTemplate">
          <v-icon left>mdi-close</v-icon>
          Delete
        </v-btn>
      </div>
    </div>

    <!-- Alerts -->
    <status-alert :error="deleteError" />

    <!-- Details -->
    <v-card>
      <v-card-header>
        <v-card-title class="pa-0">Details</v-card-title>
      </v-card-header>
      <v-card-text>
        <async-state :promise="promise">
          <template v-slot:default>
          <v-row class="row-divided">
            <v-col cols="4">
              <label-value label="ID" :value="template.id" />
              <label-value label="Profile" :value="template.profile_id" />
            </v-col>
            <v-col cols="4">
              <label-value label="Subject" :value="template.subject" />
              <label-value label="Sender" :value="template.source" />
            </v-col>
            <v-col cols="4">
              <label-value label="Created time" :value="$formatDateTime(template.create_time)" />
              <label-value label="Last updated time" :value="$formatDateTime(template.update_time)" />
            </v-col>
          </v-row>
          </template>
        </async-state>
      </v-card-text>
    </v-card>

    <!-- Body -->
    <v-card class="mt-6">
      <v-card-header class="d-flex justify-space-between align-center">
        <v-card-title class="pa-0">Body</v-card-title>
        <v-btn-toggle v-model="bodyMode" group dense>
          <v-btn value="html">HTML</v-btn>
          <v-btn value="text">Text</v-btn>
        </v-btn-toggle>
      </v-card-header>
      <v-card-text>
        <async-state :promise="promise">
          <template #default>
            <iframe class="iframe" v-if="bodyMode === 'html'" :srcdoc="htmlPreview" sandbox />
            <pre style="white-space: pre-wrap" v-else>{{template.body.text}}</pre>
          </template>
        </async-state>
      </v-card-text>
    </v-card>

    <!-- Attachments -->
    <v-card class="mt-6">
      <v-card-header>
        <v-card-title class="pa-0">
          <span>Attachments</span>
          <span v-if="template" class="grey--text ml-2">({{template.attachments.length}})</span>
        </v-card-title>
      </v-card-header>
      <v-card-text>
        <async-state :promise="promise" :has-data="template && template.attachments.length > 0">
          <template #default>
            <v-simple-table>
              <thead>
                <tr>
                  <th>File ID</th>
                  <th>Attachment Name</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="attachment in template.attachments" :key="attachment.id">
                  <td>{{attachment.file_id}}</td>
                  <td>{{attachment.name}}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </template>
        </async-state>
      </v-card-text>
    </v-card>

    <!-- Attributes -->
    <v-card class="mt-6">
      <v-card-header>
        <v-card-title class="pa-0">
          <span>Test Attributes</span>
          <span class="grey--text ml-2">({{attributesLength}})</span>
        </v-card-title>
      </v-card-header>
      <v-card-text>
        <async-state :promise="promise" :has-data="Boolean(attributesLength)">
          <template #default>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Attribute name</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, key) in template.test_attributes" :key="key">
                  <td>{{key}}</td>
                  <td>{{value}}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </template>
        </async-state>
      </v-card-text>
    </v-card>
    <confirm-dialog ref="confirm" />
    <email-test-dialog ref="emailTest" :profileId="profileId"/>
  </v-container>
</template>

<script>
import AsyncState from "../components/AsyncState"
import LabelValue from '../components/LabelValue';
import ConfirmDialog from "../components/ConfirmDialog"
import StatusAlert from "../components/StatusAlert"
import EmailTestDialog from "../components/EmailTestDialog"
import Mustache from 'mustache';

export default {
  name: "TemplateDetail",
  components: {AsyncState, LabelValue, ConfirmDialog, StatusAlert, EmailTestDialog},

  props: {
    id: String,
    profileId: String
  },

  data() {
    return {
      template: null,
      promise: null,
      bodyMode: 'html',
      htmlPreview: null,
      deleteError: null
    };
  },

  computed: {
    canEdit() {
      return this.$root.permissions.canPerform("update_delivery_template", [
        `profile:${this.profileId}`,
        `profile:${this.profileId}/delivery-template:${this.id}`
      ]);
    },

    canDelete() {
      return this.$root.permissions.canPerform("delete_delivery_template", [
        `profile:${this.profileId}`,
        `profile:${this.profileId}/delivery-template:${this.id}`
      ]);
    },
    
    canCreateDelivery() {
      return this.$root.permissions.canPerform("create_delivery", [
        `profile:${this.profileId}`,
      ]);
    },

    attributesLength() {
      if (this.template?.test_attributes) {
        return Object.keys(this.template.test_attributes).length
      } else {
        return 0
      }
    },
  },

  created() {
    this.$root.profileId = this.profileId;
    this.promise = this.load();
  },

  methods: {
    async load() {
      this.template = null;
      let response = await this.$api.get(`/profiles/${this.profileId}/delivery-templates/${this.id}`);
      this.template = response.data;
      this.htmlPreview = this.renderMoustacheHTML(this.template.body.html, this.template.test_attributes);
    },

    async deleteTemplate() {
      try {
        if (await this.$refs.confirm.present({
          message: "Are you sure you want to delete this template?",
          confirm: "Delete",
          color: "error",
          callback: async () => {
            await this.$api.delete(`/profiles/${this.profileId}/delivery-templates/${this.id}`);
          }
        })) {
          this.$router.push({name: "templates", params: {statusAlert: "Template deleted successfully"}});
        }
      } catch(err) {
        this.deleteError = err;
      }
    },

    openEmailTester() {
      this.$refs.emailTest.open(this.template.id);
    },

    renderMoustacheHTML(rawHTMLTemplate, attributes) {
      return Mustache.render(rawHTMLTemplate, { attributes });
    }
  }
};
</script>

<style scoped>
.iframe {
  width: 100%;
  height: 400px;
  border: none;
}
</style>