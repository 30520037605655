<template>
  <v-container class="px-lg-12">
    <v-form ref="form" v-model="validated">
      <!-- Header -->
      <div class="d-flex justify-space-between align-center mb-6">
        <h2 class="text-h5">
          <router-link :to="{name: 'users'}">Users</router-link>
          <v-icon class="mx-2">mdi-chevron-right</v-icon>
          <template v-if="username">
            <router-link :to="{name: 'user-detail', params: {username}}">
              {{username}}
            </router-link>
            <v-icon class="mx-2">mdi-chevron-right</v-icon>
            <span>Edit</span>
          </template>
          <span v-else>Create</span>
        </h2>
        <div>
          <v-btn
            text
            exact
            :to="username ? {name: 'user-detail', params: {username}} : {name: 'users'}"
          >
            <v-icon left>mdi-close</v-icon>
            Cancel
          </v-btn>
          <v-btn @click="save" text color="primary" :loading="isSaving" :disabled="!user || !validated"> 
            <v-icon left>mdi-check</v-icon>
            Save
          </v-btn>
        </div>
      </div>

      <!-- Alerts -->
      <status-alert :error="saveError" />

      <!-- Details -->
      <v-card class="mb-6">
        <v-card-header>
          <v-card-title class="pa-0">Details</v-card-title>
        </v-card-header>
        <v-card-text>
          <async-state :promise="promise">
            <template v-slot:default>
              <v-row class="row-divided">
                <v-col cols="4">
                  <v-text-field v-if="isEditing" label="ID" v-model="user.id" disabled/>
                  <v-text-field label="Username" v-model="user.username" :disabled="isEditing"
                  />
                </v-col>
              </v-row>
            </template>
          </async-state>
        </v-card-text>
      </v-card>

      <!-- Attributes -->
      <v-card class="mb-6">
        <v-card-header>
          <v-card-title class="pa-0">Attributes</v-card-title>
        </v-card-header>
        <v-card-text>
          <async-state :promise="promise">
            <template v-slot:default>
              <v-row class="row-divided">
                <v-col cols="4">
                  <v-text-field label="Display Name" v-model="user.name" />
                </v-col>
                <v-col cols="6">
                  <v-text-field label="Email Address" v-model="user.email" :rules="[requiredRule, emailRule]" />
                  <v-text-field label="Phone Number" v-model="user.phone_number" :rules="[phoneRule]"/>                  
                </v-col>
              </v-row>
            </template>
          </async-state>
        </v-card-text>
      </v-card>

      <!-- Permissions -->
      <v-card class="mb-6">
        <v-card-header>
          <v-card-title class="pa-0">Permissions</v-card-title>
        </v-card-header>
        <v-card-text>
          <async-state :promise="promise">
            <v-input v-model="userPermissionsString" :rules="[requiredRule, userPermissionsRule]">
              <template #default>
                <codemirror v-model="userPermissionsString" class="editor" :options="codeOptions" />
              </template>
            </v-input>
          </async-state>
        </v-card-text>
      </v-card>

    </v-form>
  </v-container>
</template>

<script>
import AsyncState from "../components/AsyncState"
import StatusAlert from "../components/StatusAlert"
import { requiredRule, emailRule, extendedWordRule, phoneRule, userPermissionsRule } from "../validation"
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/javascript/javascript.js'


export default {
  name: "UserForm",
  components: {AsyncState, StatusAlert, codemirror},
  props: {
    username: String
  },

  computed: {
    isEditing() {
      return !!this.username;
    }
  },

  data() {
    return {
      user: null,
      promise: null,
      isSaving: false,
      saveError: null,
      validated: false,
      codeOptions: { // for the codemirror editor 
        mode: "application/json",
        lineWrapping: true,
        lineNumbers: true,
        readOnly: false
      },
      userPermissionsString: null,
    };
  },

  created() {
    if (this.username) {
      this.promise = this.load();
    } else {
      this.user = {};
    }
  },

  methods: {
    requiredRule,
    emailRule,
    extendedWordRule,
    phoneRule,
    userPermissionsRule,

    async load() {
      this.user = null;
      let response = await this.$api.get("/users/username:" + this.username);
      this.user = response.data;
      this.userPermissionsString = JSON.stringify(this.user.permissions, null, 2);
    },

    async save() {

      this.user.permissions = JSON.parse(this.userPermissionsString);
      this.isSaving = true;
      this.saveError = null;

      try {
        let response, statusAlert;
        if (this.user.phone_number === "") {
          this.user.phone_number = null;
        }
          if (this.isEditing){
            response = await this.$api.put(`/users/${this.user.id}`, this.user);
            statusAlert = "User updated successfully";
          } else {
            response = await this.$api.post("/users", this.user);
            statusAlert = "User created successfully";
          }
        this.$router.push({name: "user-detail", params: {username: response.data.username, statusAlert}});
      } catch(err) {
        console.log(err)
        this.saveError = err;
        this.isSaving = false;
      }
    }
  }
};
</script>

<style scoped>

.editor >>> .CodeMirror {
  height: 400px;
}
.editor {
  width: 100%;
}

</style>