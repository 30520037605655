<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <h2 class="text-h4">Stats</h2>
    </div>

    <!-- Chart -->
    <v-card>
      <v-card-header class="d-flex justify-space-between align-center">
        <profile-picker v-model="profileId" />
        <v-btn-toggle v-model="binSize" group dense mandatory>
          <v-btn value="day" class="text-none">Day</v-btn>
          <v-btn value="month" class="text-none">Month</v-btn>
        </v-btn-toggle>
        <period-picker v-model="period" @computed="computedPeriod = $event" />
      </v-card-header>
      <v-card-text>
        <async-state v-if="profileId && computedPeriod" :promise="promise">
          <v-chart v-if="hasNonZeroStats" class="chart" :option="chartOption" autoresize />
          <p v-else class="grey--text text-center my-4">
            Nothing to display. Try selecting a wider date range.
          </p>
        </async-state>
        <p v-else-if="!profileId" class="grey--text text-center my-4">
          Select a profile to view stats
        </p>
      </v-card-text>
    </v-card>

    <!-- Figures -->
    <v-card v-if="profileId && computedPeriod && hasNonZeroStats" class="mt-6">
      <v-card-header>
        <v-card-title>Figures</v-card-title>
      </v-card-header>
      <v-card-text>
          <async-state :promise="promise">
            <v-simple-table class="mb-6">
              <thead>
                <tr>
                  <th>Date</th>
                  <th v-if="totals.deliverables > 0">Content</th>
                  <th v-if="totals.submissions > 0">Guest Data</th>
                  <th v-if="totals.deliveries > 0">Email Deliveries</th>
                  <th v-if="totals.payments > 0">Completed Payments</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.time">
                  <td>{{formatDate(item.time)}}</td>
                  <td v-if="totals.deliverables > 0">{{item.stats[0].metrics.deliverables}}</td>
                  <td v-if="totals.submissions > 0">{{item.stats[0].metrics.submissions}}</td>
                  <td v-if="totals.deliveries > 0">{{item.stats[0].metrics.deliveries}}</td>
                  <td v-if="totals.payments > 0">{{item.stats[0].metrics.payments}}</td>
                </tr>
                <tr class="text-subtitle-2">
                  <td>Total</td>
                  <td v-if="totals.deliverables > 0">{{totals.deliverables}}</td>
                  <td v-if="totals.submissions > 0">{{totals.submissions}}</td>
                  <td v-if="totals.deliveries > 0">{{totals.deliveries}}</td>
                  <td v-if="totals.payments > 0" >{{totals.payments}}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </async-state>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import ProfilePicker from "../components/ProfilePicker.vue"
import PeriodPicker from "../components/PeriodPicker.vue"
import AsyncState from "../components/AsyncState.vue"
import dayjs from "dayjs"
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {LineChart} from "echarts/charts"
import {GridComponent, TooltipComponent, LegendComponent} from "echarts/components"
import VChart from "vue-echarts"
import axios from 'axios';

use([CanvasRenderer, LineChart, GridComponent, TooltipComponent, LegendComponent]);

export default {
  name: "Stats",
  components: { ProfilePicker, PeriodPicker, VChart, AsyncState },

  data() {
    return {
      period: "last7Days",
      computedPeriod: null,
      binSize: "day",
      
      items: null,

      promise: null,
      cancelToken: null
    };
  },

  computed: {
    profileId: {
      get() {
        return this.$root.profileId;
      },
      set(newVal) {
        this.$root.profileId = newVal;
      }
    },

    totals() {
      let totals = {submissions: 0, deliverables: 0, deliveries: 0, payments: 0};

      if (!this.items)
        return totals;

      for (let item of this.items) {
        totals.submissions += item.stats[0].metrics.submissions;
        totals.deliverables += item.stats[0].metrics.deliverables;
        totals.deliveries += item.stats[0].metrics.deliveries;
        totals.payments += item.stats[0].metrics.payments;
      }

      return totals;
    },

    hasNonZeroStats() {
      return Object.values(this.totals).some(t => t > 0);
    },

    chartOption() {
      if (!this.items)
        return null;

      // See https://echarts.apache.org/en/option.html#color for default colour palette

      let series = [];
      if (this.totals.deliverables > 0) {
        series.push({
          name: "Content",
          data: this.items.map(item => item.stats[0].metrics.deliverables),
          type: 'line',
          color: '#ee6666'
        });
      }
      if (this.totals.submissions > 0) {
        series.push({
          name: "Guest Data",
          data: this.items.map(item => item.stats[0].metrics.submissions),
          type: 'line',
          color: '#5470c6'
        });
      }
      if (this.totals.deliveries > 0) {
        series.push({
          name: "Email Deliveries",
          data: this.items.map(item => item.stats[0].metrics.deliveries),
          type: 'line',
          color: '#fac858'
        });
      }
      if (this.totals.payments > 0) {
        series.push({
          name: "Completed Payments",
          data: this.items.map(item => item.stats[0].metrics.payments),
          type: 'line',
          color: '#91cc75'
        });
      }
      return {
        legend: {
          show: true
        },
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          type: 'category',
          data: this.items.map(item => this.formatDate(item.time)),
          boundaryGap: false
        },
        yAxis: {
          type: 'value'
        },
        series: series
      };
    }
  },

  methods: {
    load() {
      this.items = null;

      this.promise = (async () => {
        if (this.cancelToken)
          this.cancelToken.cancel();

        this.cancelToken = axios.CancelToken.source();

        let start_time = dayjs.utc(this.computedPeriod.start).startOf('day').toISOString();
        let end_time = dayjs.utc(this.computedPeriod.end).add(1, 'day').startOf('day').toISOString();

        let response = await this.$api.get("/stats/time-series", {
          params: {
            profile_id: this.profileId,
            bin_size: this.binSize,
            start_time,
            end_time
          },
          cancelToken: this.cancelToken.token
        });
        this.items = response.data.items;
      })();
    },

    formatDate(date) {
      if (this.binSize == "day")
        return dayjs(date).format("L");
      else
        return dayjs(date).format("MMMM YYYY");
    },
  },

  watch: {
    profileId() {
      this.load();
    },

    computedPeriod() {
      if (this.profileId)
        this.load();
    },

    binSize() {
      if (this.profileId)
        this.load();
    }
  }
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>