<template>
  <div>
    <v-dialog
      v-if="showDialog"
      v-model="showDialog"
      dark
      content-class="elevation-0"
      overlay-opacity="0.9"
      width="unset"
    >
      <div class="d-flex align-center justify-center">
        <v-btn
          v-if="nav"
          icon
          text
          :disabled="previousDisabled"
          @click="$emit('previous')"
        >
          <v-icon x-large color="white">mdi-chevron-left</v-icon>
        </v-btn>
        <div class="mx-2">
          <div v-if="nav" class="mb-1 d-flex justify-space-between align-center">
            <span class="text-caption white--text">{{
              $formatDateTime(deliverable.create_time)
            }}</span>
            <v-btn
              icon
              :to="{ name: 'content-detail', params: { id: deliverable.id } }"
            >
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
          </div>
          <v-responsive
            class="preview-content broken"
            v-if="!file"
            :aspect-ratio="1.5"
          >
            <div class="fill-y d-flex align-center justify-center grey darken-4">
              <v-icon>mdi-image-broken-variant</v-icon>
            </div>
          </v-responsive>
          <img
            v-else-if="file.mime_type.startsWith('image')"
            :src="file.url"
            class="preview-content"
          />
          <video
            v-else-if="file.mime_type.startsWith('video')"
            :src="file.url"
            class="preview-content"
            autoplay
            loop
            muted
            controls
          />
          <div class="d-flex align-center justify-space-between">
            <v-chip-group
              v-model="file"
              mandatory
              active-class="white--text"
            >
              <v-chip
                v-for="file in deliverable.files"
                :key="file.id"
                x-small
                :value="file"
                color="grey darken-2"
                >{{ file.id }}</v-chip
              >
            </v-chip-group>
            <v-btn v-if="file" text icon @click="download">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </div>
        </div>
        <v-btn
          v-if="nav"
          icon
          text
          :disabled="nextDisabled"
          @click="$emit('next')"
        >
          <v-icon x-large color="white">mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-dialog>
    <v-btn
        v-if="showDialog"
        icon
        text
        color="white"
        class="dialog-close"
        @click="showDialog = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
  </div>
</template>

<script>
export default {
  name: "DeliverablePreviewDialog",

  props: {
    nav: Boolean,
    nextDisabled: Boolean,
    previousDisabled: Boolean
  },

  data() {
    return {
      showDialog: false,
      deliverable: null,
      file: null
    }
  },

  methods: {
    open(deliverable, file) {
      this.deliverable = deliverable;
      this.file = file || deliverable.files[0];
      this.showDialog = true;
    },

    close() {
      this.deliverable = null;
      this.file = null;
      this.showDialog = false;
    },

    async download() {
      let link = document.createElement('a');

      try {
        let file = await fetch(this.file.url, {
          headers: {
            'Cache-Control': 'no-cache' // S3 CORS bug
          }
        });
        let blobUrl = URL.createObjectURL(await file.blob());
        link.href = blobUrl;
        link.download = this.deliverable.id + '_' + this.file.id;

      } catch {
        link.href = this.file.url;
        link.target = '_blank';
      }

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  }
};
</script>

<style scoped>
.preview-content {
  max-width: 70vw;
  max-height: 70vh;
  box-shadow: rgba(0, 0, 0, 0.7) 0 5px 20px 2px;
}

.preview-content.broken {
  width: 400px;
}

.dialog-close {
  position: fixed;
  top: 8px;
  right: 8px;
  z-index: 1000;
}
</style>