<template>
<div class="v-card-header pa-4">
  <slot />
</div>
</template>

<script>
export default {
  name: "VCardHeader"
}
</script>

<style>
.v-card-header {
  background-color: #fafafa;  /* grey lighten-5 */
  border-bottom: solid thin;
  border-color: #e0e0e0;  /* grey lighten-2 */
}
</style>