<template>
  <v-container class="px-lg-12">
    <v-form ref="form" v-model="validated">
      <!-- Header -->
      <div class="d-flex justify-space-between align-center mb-6">
        <h2 class="text-h5">
          <router-link :to="{name: 'templates'}">Delivery Templates</router-link>
          <v-icon class="mx-2">mdi-chevron-right</v-icon>
          <template v-if="id">
            <router-link :to="{name: 'template-detail', params: {id}}">
              {{id}}
            </router-link>
            <v-icon class="mx-2">mdi-chevron-right</v-icon>
            <span>Edit</span>
          </template>
          <span v-else>Create</span>
        </h2>
        <div>
          <v-btn
            text
            exact
            :to="id ? {name: 'template-detail', params: {id}} : {name: 'templates'}"
          >
            <v-icon left>mdi-close</v-icon>
            Cancel
          </v-btn>
          <v-btn text color="primary" :loading="isSaving" :disabled="!template || !validated" @click="save">
            <v-icon left>mdi-check</v-icon>
            Save
          </v-btn>
        </div>
      </div>

      <!-- Alerts -->
      <status-alert :error="saveError" />

      <!-- Details -->
      <v-card>
        <v-card-header>
          <v-card-title class="pa-0">Details</v-card-title>
        </v-card-header>
        <v-card-text>
          <async-state :promise="promise">
            <template v-slot:default>
              <v-row v-if="!id" dense>
                <v-col>
                  <v-text-field
                    label="ID"
                    v-model="template.id"
                    :rules="[requiredRule, extendedWordRule]"
                    :error-messages="alreadyExists ? 'A template with this ID already exists' : null"
                    @input="alreadyExists = false"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field label="Subject" v-model="template.subject" :rules="[requiredRule]" />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">
                  <v-text-field label="Sender Name" v-model="sourceName" />
                </v-col>
                <v-col cols="8">
                  <v-text-field label="Sender Address" v-model="sourceAddress" type="email" :rules="[requiredRule, emailRule]" />
                </v-col>
              </v-row>
            </template>
          </async-state>
        </v-card-text>
      </v-card>

      <!-- Body -->
      <v-card class="mt-6">
        <v-card-header class="d-flex justify-space-between align-center">
          <v-card-title class="pa-0">Body</v-card-title>
          <v-btn-toggle v-model="bodyMode" group dense>
            <v-btn value="html">HTML</v-btn>
            <v-btn value="text">Text</v-btn>
          </v-btn-toggle>
        </v-card-header>
        <v-card-text>
          <async-state :promise="promise">
            <template #default>
              <v-input :value="{...template.body}" :rules="[bodyRule]">
                <template v-if="bodyMode === 'html'">
                  <v-row class="row-divided">
                    <v-col cols="6">
                      <codemirror class="editor" v-model="template.body.html" :options="codeOptions" />
                    </v-col>
                    <v-col cols="6">
                      <iframe class="iframe" :srcdoc="htmlPreview" sandbox />
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <v-textarea v-model="template.body.text" wrap="hard" placeholder="Enter a message for recipients that only accept plain text emails" />
                </template>
              </v-input>
            </template>
          </async-state>
        </v-card-text>
      </v-card>

      <!-- Attachments -->
      <v-card class="mt-6">
        <v-card-header class="d-flex justify-space-between align-center">
          <v-card-title class="pa-0">
            <span>Attachments</span>
            <span v-if="template" class="grey--text ml-2">({{template.attachments.length}})</span>
          </v-card-title>
          <v-btn text @click="addAttachment">
            <v-icon left>mdi-plus</v-icon>
              Add
            </v-btn>
        </v-card-header>
        <v-card-text>
          <async-state :promise="promise" :has-data="template && template.attachments.length > 0">
            <template #default>
              <v-simple-table>
                <thead>
                  <tr>
                    <th>File ID</th>
                    <th>Attachment Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(attachment, index) in template.attachments" :key="index">
                    <td width="30%">
                      <v-text-field v-model="attachment.file_id" :rules="[requiredRule]" />
                    </td>
                    <td>
                      <v-text-field v-model="attachment.name" :rules="[requiredRule]" />
                    </td>
                    <td width="1%">
                      <v-btn icon @click="removeAttachment(index)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </template>
          </async-state>
        
        </v-card-text>
      </v-card>

      <!-- Attributes -->
      <v-card class="mt-6">
        <v-card-header class="d-flex justify-space-between align-center">
          <v-card-title class="pa-0">
            <span>Test Attributes</span>
            <span v-if="template" class="grey--text ml-2">({{testAttributes.length}})</span>
          </v-card-title>
          <v-btn text @click="addAttribute">
            <v-icon left>mdi-plus</v-icon>
              Add
            </v-btn>
        </v-card-header>
        <v-card-text>
          <async-state :promise="promise" :has-data="testAttributes.length > 0">
            <template #default>
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Attribute name</th>
                    <th>Type</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(attribute, index) in testAttributes" :key="index">
                    <td width="30%">
                      <v-text-field v-model="attribute.key" :rules="[requiredRule]"/>
                    </td>
                    <td width="18%">
                      <v-select class="text-capitalize"
                        :items="['String', 'Number', 'Boolean']"
                        label=""
                        v-model="attribute.type_name"
                        @change="attribute.value = null"
                      ></v-select>
                    </td>
                    <td>
                      <v-text-field v-if="attribute.type_name === `String`" v-model="attribute.value"/>
                      <v-text-field v-else-if="attribute.type_name === `Number`" type="number" v-model="attribute.value" />
                      <v-switch v-else-if="attribute.type_name === 'Boolean'" v-model="attribute.value" class="text-capitalize" ></v-switch>
                    </td>
                    <td width="1%">
                      <v-btn icon @click="removeAttribute(index)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </template>
          </async-state>
        
        </v-card-text>
      </v-card>
    
    </v-form>
  </v-container>
</template>

<script>
import AsyncState from "../components/AsyncState"
import StatusAlert from "../components/StatusAlert"
import {requiredRule, emailRule, extendedWordRule} from "../validation"
import Mustache from 'mustache';
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/htmlmixed/htmlmixed.js'

export default {
  name: "TemplateForm",
  components: {AsyncState, StatusAlert, codemirror},

  props: {
    id: String,
    profileId: String
  },

  data() {
    return {
      template: null,
      promise: null,
      
      sourceName: null,
      sourceAddress: null,
      
      bodyMode: "html",
      codeOptions: {
        mode: "text/html",
        lineWrapping: true,
        lineNumbers: true
      },
      htmlPreview: null,
      htmlPreviewTimer: null,

      testAttributes: [],
      
      isSaving: false,
      saveError: null,
      alreadyExists: false,
      validated: false
    };
  },

  created() {
    this.$root.profileId = this.profileId;

    if (this.id) {
      this.promise = this.load();
    } else {
      this.template = {
        profile_id: this.profileId,
        id: "default",
        subject: "Your Content",
        body: {
          html: "<html>\n  <body>\n    <p>Please find your content attached.</p>\n  </body>\n</html>"
        },
        attachments: [
          {
            file_id: "0",
            name: "Your Content"
          }
        ],
        test_attributes: {}
      }
      this.sourceName = "Content";
      this.sourceAddress = "content@itstheflashpack.com"
    }
  },

  methods: {
    async load() {
      this.template = null;
      let response = await this.$api.get(`/profiles/${this.profileId}/delivery-templates/${this.id}`);
      this.template = response.data;
      this.testAttributes = this.convertAttributesToArray(this.template.test_attributes);

      let match = this.template.source.match(/^\s*(?:(?:|(?:"(.*)")|(.+?))\s+)?<\s*(\S+)\s*>\s*$/);
      if (match) {
        this.sourceName = match[1] || match[2];
        this.sourceAddress = match[3];
      } else {
        this.sourceAddress = this.template.source;
      }
    },

    requiredRule,
    emailRule,
    extendedWordRule,
    bodyRule(body) {
      return !!(body.html || body.text) || "Either an HTML or plain text body is required";
    },

    addAttachment() {
      this.template.attachments.push({
        file_id: this.template.attachments.length.toString(),
        name: "Your Content"
      });
    },
    
    removeAttachment(index) {
      this.template.attachments.splice(index, 1);
    },

    addAttribute() {
      this.testAttributes.push({
        key: `attribute${this.testAttributes.length+1}`,
        value: null,
        type_name: 'String'
      });
    },

    removeAttribute(index) {
      this.testAttributes.splice(index, 1);
    },

    convertAttributesToObject(attributes) {
      const attributesObject = {};
      attributes.forEach(obj => {
        if (obj.type_name == 'String') {
          obj.value = obj.value || "";
        }
        if(obj.type_name === 'Number') {
          obj.value = parseFloat(obj.value) || 0;
        }
        if(obj.type_name === 'Boolean') {
          obj.value = obj.value || false;
        } 

        attributesObject[obj.key] =  obj.value; 
      });
      return attributesObject
    },

    convertAttributesToArray(obj) {
      const attributesArray = [];
      for (const key in obj) {
        let value = obj[key];
        let type = typeof value;
        attributesArray.push({ 
          key: key, 
          value: value, 
          type_name: type[0].toUpperCase() + type.slice(1)
        });
      }
      return attributesArray;
    },

    async save() {
      this.template.test_attributes = this.convertAttributesToObject(this.testAttributes);

      if (this.sourceName)
        this.template.source = `${JSON.stringify(this.sourceName)} <${this.sourceAddress}>`;
      else
        this.template.source = this.sourceAddress;

      this.isSaving = true;
      this.saveError = null;
      try {
        let response, statusAlert;
        if (this.id) {
          response = await this.$api.put(`/profiles/${this.profileId}/delivery-templates/${this.id}`, this.template);
          statusAlert = "Template updated successfully";
        } else {
          response = await this.$api.post(`/profiles/${this.profileId}/delivery-templates`, this.template);
          statusAlert = "Template created successfully";
        }
        this.$router.push({name: "template-detail", params: {id: response.data.id, statusAlert}});
      } catch(err) {
        if (err.response?.status === 409)
          this.alreadyExists = true;
        else
          this.saveError = err;
      } finally {
        this.isSaving = false;
      }
    },

    renderMoustacheHTML(rawHTMLTemplate) {
      let attributes = this.convertAttributesToObject(this.testAttributes);
      return Mustache.render(rawHTMLTemplate, { attributes });
    }
  },

  computed: {
    htmlData() {
      // Hack that allows us to watch both the body and attributes
      return [this.template?.body.html, this.testAttributes];
    }
  },

  watch: {
    htmlData: {
      handler() {
        clearTimeout(this.htmlPreviewTimer);
        this.htmlPreviewTimer = setTimeout(() => {
          this.htmlPreview = this.renderMoustacheHTML(this.template.body.html);
        }, 500);
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.iframe {
  width: 100%;
  height: 400px;
  border: none;
}

.editor >>> .CodeMirror {
  height: 400px;
}
</style>