import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from './views/Login';
import Content from './views/Content';
import ProfileSchedules from './views/ProfileSchedules';
import ProfileTemplates from './views/ProfileTemplates';
import CreateProfileTemplate from './views/CreateProfileTemplate';
import EditProfile from './views/EditProfile';
import ContentDetail from './views/ContentDetail';
import Data from './views/Data';
import DataDetail from './views/DataDetail';
import Payment from './views/Payments.vue';
import Dashboard from './views/Dashboard'
import Templates from './views/Templates';
import TemplateDetail from './views/TemplateDetail';
import TemplateForm from './views/TemplateForm';
import Deliveries from './views/Deliveries';
import DeliveryDetail from './views/DeliveryDetail';
import Stats from './views/Stats';
import Profiles from './views/Profiles'
import ProfileDetail from './views/ProfileDetail'
import ProfileForm from './views/ProfileForm'
import Monitoring from './views/Monitoring';
import Users from './views/Users';
import UserDetail from './views/UserDetail';
import UserForm from './views/UserForm';

Vue.use(VueRouter);

export default new VueRouter({
  mode: 'history',
  routes: [
    { path: '/login', name: 'login', component: Login },
    { path: '/profile-admin', name: "profile-admin", component: Profiles, props: true},
    { path: "/profile-admin/:profileId", name: "profile-detail", component: ProfileDetail, props: true},
    { path: "/profile-admin/:profileId/edit", name: "profile-edit", component: ProfileForm, props: true},
    {
      path: '/profile/:profileName/schedule/:index',
      name: 'profile',
      component: EditProfile,
    },
    {
      path: '/profile/:profileName/new-schedule',
      name: 'profile',
      component: EditProfile,
    },
    {
      path: '/profile/:profileName/default',
      name: 'profile',
      component: EditProfile,
    },
    { path: '/profiles', name: 'profiles', component: ProfileSchedules },
    { path: '/profile-templates', name: 'profile-templates', component: ProfileTemplates },
    { path: '/profile-template/:profileName/create', name: 'profile-templates', component: CreateProfileTemplate },
    {
      path: '/profiles/:profileName',
      name: 'profiles',
      component: ProfileSchedules,
    },
    { path: '/content', name: 'content', component: Content },
    { path: '/dashboard', name: "dashboard", component: Dashboard },
    {
      path: '/content/:id',
      name: 'content-detail',
      component: ContentDetail,
      props: true,
    },
    { path: '/data', name: 'data', component: Data },
    {
      path: '/data/:id',
      name: 'data-detail',
      component: DataDetail,
      props: true,
    },
    { path: '/payments', name: 'payment', component: Payment },
    { path: '/templates', name: 'templates', component: Templates },
    {
      path: '/templates/:profileId/create',
      name: 'template-create',
      component: TemplateForm,
      props: true,
    },
    {
      path: '/templates/:profileId/:id',
      name: 'template-detail',
      component: TemplateDetail,
      props: true,
    },
    {
      path: '/templates/:profileId/:id/edit',
      name: 'template-edit',
      component: TemplateForm,
      props: true,
    },
    { path: '/deliveries', name: 'deliveries', component: Deliveries },
    {
      path: '/deliveries/:id',
      name: 'delivery-detail',
      component: DeliveryDetail,
      props: true,
    },
    { path: '/stats', name: 'stats', component: Stats },
    { path: '/monitoring', name: 'monitoring', component: Monitoring },
    { path: '/users', name: 'users', component: Users },
    {
      path: '/users/create',
      name: 'user-create',
      component: UserForm,
      props: true,
    },
    {
      path: '/users/:username',
      name: 'user-detail',
      component: UserDetail,
      props: true,
    },
    {
      path: '/users/:username/edit',
      name: 'user-edit',
      component: UserForm,
      props: true,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
