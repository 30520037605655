<template>
  <div>
    <v-simple-table v-if="items.length">
      <thead>
        <tr>
          <th>Created time</th>
          <th>Status</th>
          <th>Amount</th>
          <th>Card Details</th>
          <th>Message</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ $formatDateTime(item.create_time) }}</td>
          <td><status-chip :status="item.status" small /></td>
          <td>{{ (item.amount/100).toFixed(2) + " " + item.currency}}</td>
          <td>{{ item.brand_name }} {{ item.masked_pan }}</td>
          <td>{{ item.message }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <async-state :promise="promise" :has-data="items.length > 0">
      <div v-if="nextPageToken" class="text-center my-4">
        <v-btn text @click="loadMore">
          Load more
          <v-icon right>mdi-chevron-down</v-icon>
        </v-btn>
      </div>
    </async-state>
  </div>
</template>

<script>
import AsyncState from "./AsyncState.vue"
import axios from 'axios'
import StatusChip from "../components/StatusChip"

export default {
  name: "PaymentsTable",
  components: { AsyncState ,StatusChip},

  props: {
    profileId: String,
    pageSize: Number,
    reverse: Boolean,
    startTime: Date,
    endTime: Date,
  },

  data() {
    return {
      profile: null,
      items: [],
      promise: null,
      nextPageToken: null,
      cancelToken: null
    }
  },

  computed: {
    baseParams() {
      return {
        profile_id: this.profileId,
        order_by: this.reverse ? "desc" : "asc",
        start_time: this.startTime?.toISOString(),
        end_time: this.endTime?.toISOString()
      }
    },
  },

  methods: {
    loadInit() {
      this.items = [];
      this.nextPageToken = null;
      this.loadMore();
    },

    loadMore() {
      this.promise = (async () => {
        if (this.cancelToken)
          this.cancelToken.cancel();

        this.cancelToken = axios.CancelToken.source();

        let response = await this.$api.get("/payments", {
          params: {
            ...this.baseParams,
            page_size: this.pageSize,
            page_token: this.nextPageToken
          },
          cancelToken: this.cancelToken.token
        });

        this.items = this.items.concat(response.data.items);

        this.nextPageToken = response.data.next_page_token;
      })();
    },

    isUndefinedOrNull(value) {
      return typeof (value) === "undefined" || value === null;
    }
  },

  watch: {
    baseParams: {
      handler() {
        this.loadInit();
      },
      immediate: true
    },

    profileId: {
      async handler(newValue) {
        this.profile = null;
        if (newValue) {
          this.profile = await this.$root.getProfile(newValue);
        }
      },
      immediate: true
    }
  }
}
</script>

<style></style>