<template>
  <v-card>
    <v-card-header>
      <div class="d-flex align-center">
        <v-card-title>{{resource.name || resource.id}}</v-card-title>
        <v-tooltip bottom :color="isOnline ? 'success' : 'error'" :disabled="!resource.latest_event">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              :color="isOnline ? 'success' : 'error'"
              v-text="isOnline ? 'Online' : 'Offline'"
              v-on="on"
              v-bind="attrs" />
          </template>
          <span v-if="resource.latest_event">
            Last seen: {{$dayjs(resource.latest_event.sync_time).fromNow()}}
          </span>
        </v-tooltip>
      </div>
    </v-card-header>
    <v-card-text v-if="metricGroups.length" :class="{dimmed: !isOnline}">
      <div v-for="(group, index) in metricGroups" :key="group.name">
        <v-divider v-if="index !== 0" class="my-5" />
        <h3 class="text-subtitle-1 mb-3">{{group.name}}</h3>
        <monitored-resource-metric
          v-for="metric in group.metrics"
          :key="metric.descriptor.id"
          :descriptor="metric.descriptor"
          :metric="metric.metric"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import MonitoredResourceMetric from './MonitoredResourceMetric.vue'
import {isResourceOnline} from '../utils'

export default {
  name: "MonitoredResourceCard",
  components: {MonitoredResourceMetric},
  
  props: {
    resource: Object
  },

  computed: {
    isOnline() {
      return isResourceOnline(this.resource);
    },

    allMetrics() {
      let metricsObj = {};
      if (this.resource.latest_event)
        metricsObj = Object.fromEntries(this.resource.latest_event.metrics.map(metric => [metric.id, metric]));
      return this.resource.metric_descriptors.map(descriptor => ({descriptor, metric: metricsObj[descriptor.id]}));
    },

    metricGroups() {
      let groups = [];

      let metrics = this.allMetrics;

      let printerMetrics = metrics.filter(metric => metric.descriptor.type.split(".")[0] === "printer");
      if (printerMetrics.length)
        groups.push({name: "Printer", metrics: printerMetrics});

      let deviceMetrics = metrics.filter(metric => metric.descriptor.type.split(".")[0] === "device");
      if (deviceMetrics.length)
        groups.push({name: "Devices", metrics: deviceMetrics});
    
      let serviceMetrics = metrics.filter(metric => metric.descriptor.type.split(".")[0] === "service");
      if (serviceMetrics.length)
        groups.push({name: "Services", metrics: serviceMetrics});

      let systemMetrics = metrics.filter(metric => metric.descriptor.type.split(".")[0] === "system");
      if (systemMetrics.length)
        groups.push({name: "System", metrics: systemMetrics});

      return groups;
    }
  }
}
</script>

<style scoped>
.dimmed {
  opacity: 0.25;
}
</style>