<template>
  <v-container>
    <v-card>
      <v-row v-if="loading" justify="center" align="center" style="height: 100%;">
        <v-col cols="auto">
          <v-progress-circular indeterminate width="2" size="24"></v-progress-circular>
        </v-col>
      </v-row>

      <template v-if="!loading">
        <v-card-title>
          {{ this.$route.params.profileName }} - Create a new profile
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="templateName" label="Template name" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <date-time-picker label="Start date" :required="true" :clearable="true"
                    v-model="startDate"></date-time-picker>
                </v-col>
                <v-col cols="6">
                  <date-time-picker label="End date" :required="true" :clearable="true"
                    v-model="endDate"></date-time-picker>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="createProfile" :disabled="!isFormValid">Save</v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import DateTimePicker from '../components/DateTimePicker.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'ProfileForm',
  components: {
    DateTimePicker
  },
  data() {
    return {
      templateName: null,
      startDate: null,
      endDate: null,
      profile: null,
      loading: false,
    };
  },

  created() {
    this.fetchProfile(this.$route.params.profileName);
  },
  computed: {
    isFormValid() {
      return this.templateName && this.startDate && this.endDate;
    },
  },
  methods: {
    async fetchProfile(profileName) {
      if (profileName) {
        try {
          this.loading = true;
          this.profile = await this.$root.getProfileFromBucket(profileName);
          this.loading = false;
        } catch (err) {
          console.error('Error fetching profile', err);
          this.loading = false;
        }
      } else {
        this.profile = null;
      }
    },
    async createProfile() {
      this.loading = true;

      try {

        const startYear = new Date(this.startDate).getFullYear();
        const startMonth = new Date(this.startDate).toLocaleString('default', { month: 'short' });

        const profilePrefix = 'event';
        const uniqueId = uuidv4().substring(0, 8);
        const updatedProfile = `${profilePrefix}.${startYear}.${startMonth}.${this.templateName}-${uniqueId}`;

        this.profile.profile = updatedProfile;
        this.profile.profile_name = this.templateName;
        this.profile.start_date = this.startDate;
        this.profile.end_date = this.endDate;

        const response = await this.$root.cloneProfile(this.$route.params.profileName, updatedProfile, this.profile);
        if (response.status == 200) {
          this.$router.push(`/profile/${updatedProfile}/default`);
        }
      }
      catch (e) {
        console.error('Error cloning profile', e);
      }
      this.loading = false;


    }
  },

};
</script>

<style scoped>
.group-title {
  margin-bottom: 10px;
  margin-top: 30px;
}
</style>
