<template>
  <div>
    <div>
      <h3 style="font-weight: 400; margin-bottom: 10px;">{{ label }}</h3>
      <p v-if="hint" class="text-caption">{{hint}}</p>
      <v-card elevation="1" class="fixed-card">
        <v-card-text v-if="!previewImage" class="text-center" @click="uploadFile()">
          <v-icon size="48" color="secondary">mdi-cloud-upload</v-icon>
          <p>Upload a file</p>
        </v-card-text>
        <template v-else>
          <img v-if="fileType == 'image'" :src="previewImage" class="preview-image chequered-bg">
          <video v-else-if="fileType == 'video'" :src="previewImage" class="preview-image chequered-bg" preload="metadata" />
          <v-card-text v-else class="text-center">
            <v-icon size="48" color="secondary">mdi-file-outline</v-icon>
          </v-card-text>
        </template>
      </v-card>
      <input ref="fileInput" type="file" :accept="accept" @change="onFileChange" style="display: none">
    </div>
    <div v-if="file" class="file-info">
      <v-icon size="22" color="primary">mdi-paperclip</v-icon>
      {{ file.name }}
      <v-icon class="delete-file" @click="removeFile()">mdi-close</v-icon>
    </div>
  </div>
</template>
<style scoped>
.delete-file {
  color: red;
  cursor: pointer;
}

.file-info {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
}



.fixed-card {
  width: 300px;
  height: 150px;
}

.preview-image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.chequered-bg {
  background-color: #e3e3e3;
  background: repeating-conic-gradient(#ccc 0% 25%, #eee 0% 50%) 0 / 16px 16px;
}

</style>

<script>
export default {
  name: 'FileInput',

  props: {
    label: {
      type: String,
      default: 'Upload',
    },
    accept: {
      type: String,
      default: null,
    },
    controlId: String,
    initialFile: {
      type: String,
      default: null,
    },
    hint: String
  },

  data() {
    return {
      previewImage: null,
      file: null,
    };
  },

  computed: {
    fileType() {
      if (!this.file) return null;

      if (this.file.type)
        return this.file.type.split("/")[0];
      
      let ext = this.file.name.split(".").pop().toLowerCase();
      if (ext == "png" || ext == "jpg" || ext == "jpeg" || ext == "gif")
        return "image"
      if (ext == "mp4" || ext == "webm")
        return "video";
      return "unknown";
    }
  },

  mounted() {
    this.updatePreviewImage();
  },

  methods: {
    uploadFile() {
      this.$refs.fileInput.click();
    },

    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.file = files[0];
      this.createImage(this.file, (imageURL) => {
        this.previewImage = imageURL;
        this.$emit('file-changed', this.file);
      });
    },

    createImage(file, callback) {
      const reader = new FileReader();
      reader.onload = (e) => {
        callback(e.target.result);
      };
      reader.readAsDataURL(file);
    },

    removeFile() {
      this.previewImage = null;
      this.file = null;
      this.$refs.fileInput.value = '';
      this.$emit('file-removed');
    },

    updatePreviewImage() {
      if (this.initialFile) {
        const fileName = this.initialFile.slice(0, this.initialFile.lastIndexOf('.'));
        const ext = this.initialFile.substring(this.initialFile.lastIndexOf('.'));
        this.previewImage = `https://airtime-dev-profiles.s3.eu-west-2.amazonaws.com/profiles/${this.$route.params.profileName}/${fileName}`;
        this.file = { name:  this.controlId + ext };
      }
    },
  },
};
</script>
