export function convertLegacyMetric(metric) {
  // convert legacy metrics if needed - can eventually be phased out
  // legacy metrics didn't have a status property

  if (!metric)
    return null;

  let status = metric.status;
  let value = metric.value;

  if (!status && ["ok", "warning", "error"].includes(value)) {
    // legacy status
    status = value;
    value = null;

  } else if (!status && value != null && ["system.memory-usage", "system.disk-usage"].includes(metric.id)) {
    // legacy usage
    if (value >= 0.9)
      status = "error";
    else if (value >= 0.75)
      status = "warning";
    else
      status = "ok";
  }

  return {
    id: metric.id,
    status,
    value,
    message: metric.message
  }
}

export function isResourceOnline(resource) {
  // slightly convoluted - the api should perhaps have an easier way of providing this

  if (!resource.has_recent_event)
    return false;

  for (let metric of resource.latest_event.metrics) {
    if (metric.id === "online")
      return metric.value === "ok" || metric.status == "ok";
  }

  return true;
}
