<template>
  <v-dialog v-model="showDialog" max-width="600px">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span>Download</span>
        <v-btn text icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>
          Download data as a CSV file.
        </p>

        <v-expansion-panels v-model="panel" class="panels">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <span class="font-weight-medium">Profile and dates</span>
                <v-fade-transition>
                  <span v-if="!open" class="mx-3 flex-grow-0 text--secondary">
                    {{ profileId || "Select a profile" }} /
                    {{ computedPeriod ? computedPeriod.formatted : "Select a period" }}
                  </span>
                </v-fade-transition>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <profile-picker
                v-model="profileId"
                @input="reloadFilters"
                :disabled="isLoading"
              />
              <period-picker
                v-model="period"
                @computed="computedPeriod = $event"
                :disabled="isLoading"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <span class="font-weight-medium">Filters</span>
                <v-fade-transition>
                  <span v-if="!open && enabledFilters.length" class="mx-3 flex-grow-0 text--secondary">
                    <template v-if="enabledFilters.length === 1">
                      {{enabledFilters[0].id}} is {{enabledFilters[0].value}}
                    </template>
                    <template v-else>
                      {{enabledFilters.length}} active filters
                    </template>
                  </span>
                </v-fade-transition>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <filters-list v-model="filters" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <template v-if="isLoading">
          <v-spacer />
          <p class="grey--text mb-1">
            <v-progress-circular
              indeterminate
              width="2"
              size="16"
              class="mr-2"
              color="grey"
            />
            Exporting data
          </p>
        </template>
        <template v-else-if="error">
          <v-spacer />
          <p class="error--text mb-1">
            An error occurred<template v-if="error.response">: {{error.response.data.detail}}</template>
          </p>
        </template>
        <template v-else>
          <v-spacer />
          <v-btn
            text
            color="primary"
            :disabled="!profileId || !computedPeriod"
            @click="exportData"
            >Download</v-btn
          >
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProfilePicker from './ProfilePicker.vue';
import PeriodPicker from './PeriodPicker.vue';
import FiltersList from './FiltersList.vue';
import Filters from "../filters";
import dayjs from 'dayjs';
import axios from 'axios';

export default {
  name: "ExportDialog",
  components: {ProfilePicker, PeriodPicker, FiltersList},

  data() {
    return {
      showDialog: false,
      panel: 0,
      
      profileId: null,
      period: null,
      computedPeriod: null,
      
      filters: null,

      isLoading: false,
      error: null,
      cancelToken: null,
    }
  },

  computed: {
    enabledFilters() {
      return this.filters.enabled;
    }
  },

  methods: {
    open(profileId, period, filters) {
      this.profileId = profileId;
      this.period = period;
      if (!filters)
        this.reloadFilters()
      else 
        this.filters = filters;
        
      this.isLoading = false;
      this.error = null;
      this.panel = 0;
      this.showDialog = true;
    },

    close() {
      this.showDialog = false;
      if (this.cancelToken !== null)
        this.cancelToken.cancel();
    },

    async exportData() {
      this.isLoading = true;
      this.cancelToken = axios.CancelToken.source();

      let startTime = dayjs(this.computedPeriod.start);
      let endTime = dayjs(this.computedPeriod.end);
      let startTimeISO = startTime.startOf('day').toISOString();
      let endTimeISO = endTime.add(1, 'day').startOf('day').toISOString();
      
      let nextPageToken = null;
      let items = [];

      try {
        do {
          let response = await this.$api.get('/submissions', {
            params: {
              profile_id: this.profileId,
              start_time: startTimeISO,
              end_time: endTimeISO,
              page_token: nextPageToken,
              page_size: 100
            },
            cancelToken: this.cancelToken.token
          });

          items = items.concat(this.filters.apply(response.data.items));
          nextPageToken = response.data.next_page_token;

        } while (nextPageToken);

        let name = `${this.profileId} - ${startTime.format('L')} - ${endTime.format('L')} - Data`
        this.downloadCSV(items, name);

      } catch(err) {
        this.error = err;
        throw(err);
      } finally {
        this.isLoading = false;
      }
    },

    downloadJSON(data, name) {
      this.downloadData(JSON.stringify(data, null, 2), 'application/json', name + '.json');
    },

    downloadCSV(data, name) {
      let attributeKeys = new Set();
      for (let item of data) {
        for (let attribute in item.attributes) {
          attributeKeys.add(attribute);
        }
      }
      attributeKeys = Array.from(attributeKeys);

      let rows = [];
      rows.push(['id', 'create_time', 'content_ids'].concat(attributeKeys))
      for (let item of data) {
        let deliverableIds = item.latest_deliverables.map(deliverable => deliverable.id).join(",");
        let attributes = attributeKeys.map((key) => item.attributes[key]);
        rows.push([item.id, item.create_time, deliverableIds].concat(attributes));
      }

      let csvData = '';
      for (let row of rows) {
        let csvRow = row.map((col) => {
          if (col === null || col === undefined)
            return '';
          if (typeof col === 'string')
            return '"' + col.replaceAll('"', '""') + '"';
          return col.toString();
        })
        csvData += csvRow.join(',') + '\n';
      }

      this.downloadData(csvData, 'text/csv', name + '.csv');
    },

    downloadData(data, type, name) {
      let file = new Blob([data], {type});
      let a = document.createElement('a');
      a.href = URL.createObjectURL(file);
      a.download = name;
      a.click();
    },

    async reloadFilters() {
      this.filters = new Filters();
      let profile = await this.$root.getProfile(this.profileId);
      this.filters = Filters.fromSchema(profile.schemas.submission);
    }
  },

  watch: {
    profileId() {
      this.error = null;
    },

    period() {
      this.error = null;
    }
  }
};
</script>

<style scoped>
.disabled {
  opacity: 0.5;
}
</style>