<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <h2 class="text-h5">
        <router-link :to="{name: 'content'}">Content</router-link>
        <v-icon class="mx-2">mdi-chevron-right</v-icon>
        <span>{{id}}</span>
      </h2>
      <div>
        <v-btn v-if="canErase" text color="error" @click="erase">
          <v-icon left>mdi-eraser</v-icon>
          Erase
        </v-btn>
      </div>
    </div>

    <!-- Alerts -->
    <status-alert v-if="eraseSuccess" type="success">Content erased successfully</status-alert>
    <status-alert :error="eraseError" />

    <!-- Details -->
    <v-card>
      <v-card-header>
        <v-card-title class="pa-0">Details</v-card-title>
      </v-card-header>
      <v-card-text>
        <async-state :promise="deliverablePromise">
          <template v-slot:default>
          <v-row class="row-divided">
            <v-col cols="4">
              <label-value label="Thumbnail">
                <deliverable-thumbnail :deliverable="deliverable" :max-height="150" />
              </label-value>
            </v-col>
            <v-col cols="4">
              <label-value label="ID" :value="deliverable.id" />
              <label-value label="Profile" :value="deliverable.profile_id" />
              <label-value label="Status">
                <status-chip :status="deliverable.status" small />
              </label-value>
            </v-col>
            <v-col cols="4">
              <label-value label="Created time" :value="$formatDateTime(deliverable.create_time)" />
              <label-value label="Synced time" :value="$formatDateTime(deliverable.sync_time)" />
            </v-col>
          </v-row>
          </template>
        </async-state>
      </v-card-text>
    </v-card>

    <v-row class="mt-6">
      <v-col cols="12" md="6">
        <!-- Files -->
        <v-card height="100%">
          <v-card-header>
            <v-card-title class="pa-0">
              <span>Files</span>
              <span v-if="deliverable" class="grey--text ml-2">({{deliverable.files.length}})</span>
            </v-card-title>
          </v-card-header>
          <v-card-text>
            <async-state :promise="deliverablePromise" :has-data="deliverable && deliverable.files.length > 0">
              <template #default>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>File ID</th>
                      <th>Type</th>
                      <th>Size</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="file in deliverable.files" :key="file.id">
                      <td>
                        <a @click="showPreview(file)">{{file.id}}</a>
                      </td>
                      <td>{{file.mime_type}}</td>
                      <td>{{prettyBytes(file.size_bytes)}}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </template>
            </async-state>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <!-- Attributes -->
        <v-card height="100%">
          <v-card-header>
            <v-card-title class="pa-0">
              <span>Attributes</span>
              <span v-if="deliverable" class="grey--text ml-2">({{attributesLength}})</span>
            </v-card-title>
          </v-card-header>
          <v-card-text>
            <async-state :promise="deliverablePromise" :has-data="Boolean(attributesLength)">
              <template #default>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Attribute name</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, key) in deliverable.attributes" :key="key">
                      <td>{{key}}</td>
                      <td>{{value}}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </template>
            </async-state>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Data -->
    <v-card class="mt-6">
      <v-card-header>
        <v-card-title class="pa-0">
          <span>Linked Guest Data</span>
          <span v-if="deliverable" class="grey--text ml-2">({{deliverable.submissions_count}})</span>
        </v-card-title>
      </v-card-header>
      <v-card-text>
        <submissions-table :deliverable-id="id" :profile-id="deliverable?.profile_id" />
      </v-card-text>
    </v-card>

    <!-- Preview Dialog -->
    <deliverable-preview-dialog ref="preview" />

    <confirm-dialog ref="confirm" />

  </v-container>
</template>

<script>
import AsyncState from "../components/AsyncState"
import StatusChip from "../components/StatusChip"
import SubmissionsTable from "../components/SubmissionsTable.vue"
import LabelValue from "../components/LabelValue.vue"
import DeliverableThumbnail from "../components/DeliverableThumbnail.vue"
import DeliverablePreviewDialog from "../components/DeliverablePreviewDialog.vue"
import StatusAlert from "../components/StatusAlert"
import ConfirmDialog from "../components/ConfirmDialog"
import prettyBytes from "pretty-bytes";


export default {
  name: "ContentDetail",
  components: {AsyncState, StatusChip, SubmissionsTable, LabelValue, DeliverableThumbnail, DeliverablePreviewDialog, StatusAlert, ConfirmDialog},

  props: {
    id: String,
  },

  data() {
    return {
      deliverable: null,
      deliverablePromise: null,

      eraseSuccess: false,
      eraseError: null
    };
  },

  computed: {
    attributesLength() {
      if (!this.deliverable)
        return null;
      return Object.keys(this.deliverable.attributes).length;
    },

    canErase() {
      return this.$root.permissions.canPerform("erase_deliverable", {
        deliverable: this.id,
        profile: this.deliverable?.profile_id
      });
    }
  },

  created() {
    this.deliverablePromise = this.loadDeliverable();
  },

  methods: {
    async loadDeliverable() {
      this.deliverable = null;
      let response = await this.$api.get("/deliverables/" + this.id);
      this.deliverable = response.data;
      this.$root.profileId = response.data.profile_id;
    },

    showPreview(file) {
      this.$refs.preview.open(this.deliverable, file);
    },

    async erase() {
      try {
        if (await this.$refs.confirm.present({
          message: "Are you sure you want to erase personal data?",
          confirm: "Erase",
          color: "error",
          callback: async () => {
            let response = await this.$api.post(`/deliverables/${this.id}:erase`);
            this.deliverable = response.data;
          }
        })) {
          this.eraseSuccess = true;
          this.eraseError = null;
        }
      } catch(err) {
        this.eraseSuccess = false;
        this.eraseError = err;
      }
    },

    prettyBytes
  }
};
</script>
