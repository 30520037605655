import Ajv from 'ajv'
const ajv = new Ajv();

export function requiredRule(value) {
  return !!value || "This field is required";
}

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export function emailRule(value) {
  return emailRegex.test(value) || "Invalid email address";
}

export const phoneRegex = /^\+[1-9][0-9]{0,24}$/;
export function phoneRule(value) {
  if (!value) return true;
  return phoneRegex.test(value) || "Please check your phone number, and include the country code.";
}

export const wordRegex = /^\w+$/;
export function wordRule(value) {
  return wordRegex.test(value) || "The characters can be letters, numbers, or underscores (_)";
}

export function unsignedIntegerRule(value) {
  return Number.isInteger(value) && value >= 0 || 'The value must be an integer greater than or equal to 0';
}

export const extendedWordRegex = /^[\w\-.]+$/;
export function extendedWordRule(value) {
  return extendedWordRegex.test(value) || "The characters can be letters, numbers, underscores (_), hyphens(-), or dots(.)";
}

export const userPermissionsSchema = {
  "type": "object",
  "properties": {
    "statements": {
      "type": "array",
      "items": {
        "required": ["actions", "resources"],
        "type": "object",
        "properties": {
          "actions": {
            "type": "array",
            "items": { "type": "string" }
          },
          "resources": {
            "type": "array",
            "items": { "type": "string" }
          },
          "conditions": {
            "type": ["object", "null"]
          }
        }
      }
    }
  }
}
export function userPermissionsRule(value) {
  try {
    value = JSON.parse(value);
    return ajv.validate(userPermissionsSchema, value) || "Invalid permissions format";
  } catch (err) {
    return "Invalid JSON object"
  }
}
