<template>
  <v-dialog v-model="dialog" max-width="400" :persistent="isLoading" @input="cancel">
    <v-card>
      <v-card-title>{{options.title || "Confirm"}}</v-card-title>
      <v-card-text>{{options.message || "Are you sure?"}}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text :disabled="isLoading" @click="cancel">
          {{options.cancel || "Cancel"}}
        </v-btn>
        <v-btn text :color="options.color || 'primary'" :loading="isLoading" @click="confirm">
          {{options.confirm || "Confirm"}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",

  data() {
    return {
      dialog: false,
      options: {},
      resolve: null,
      reject: null,
      isLoading: false
    }
  },

  methods: {
    present(options = {}) {
      this.options = options;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },

    async confirm() {
      if (this.options.callback) {
        this.isLoading = true;
        try {
          await this.options.callback();
        } catch(err) {
          this.dialog = false;
          this.reject(err);
          return;
        } finally {
          this.isLoading = false;
        }
      }

      this.dialog = false;
      this.resolve(true);
    },

    cancel() {
      this.dialog = false;
      this.resolve(false);
    }
  }
}
</script>
