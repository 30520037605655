<template>
  <v-chip class="text-capitalize" :color="color" v-bind="$attrs">
    {{text}}
  </v-chip>
</template>

<script>
export default {
  name: "StatusChip",
  props: {
    status: String
  },

  computed: {
    text() {
      return this.status.split("_").join(" ");
    },

    color() {
      if (this.status === "complete" || this.status == "confirmed" || this.status === "delivered")
        return "success";
      if (this.status === "failed" || this.status === "declined")
        return "error";
      if (this.status === "cancelled" || this.status === "bounced")
        return "warning";
      return "info";
    }
  }
}
</script>