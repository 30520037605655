<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <h2 class="text-h4">Deliveries</h2>
    </div>

    <!-- Card -->
    <v-card>
      <v-card-header class="d-flex justify-space-between align-center">
        <profile-picker v-model="profileId" />
        <div>
          <period-picker v-model="period" @computed="computedPeriod = $event" show-recent />
        </div>
      </v-card-header>
      <v-card-text>
        <!-- Table -->
        <deliveries-table
          v-if="profileId && computedPeriod"
          :profile-id="profileId"
          :start-time="startTime"
          :end-time="endTime"
          :reverse="computedPeriod.preset === 'recent'"
          :page-size="20" />
        <p v-else-if="!profileId" class="grey--text text-center my-4">
          Select a profile to view data
        </p>
      </v-card-text>
    </v-card>

    <!-- Export Dialog -->
    <export-dialog ref="export" />

  </v-container>
</template>

<script>
import ProfilePicker from "../components/ProfilePicker";
import PeriodPicker from "../components/PeriodPicker.vue";
import ExportDialog from "../components/ExportDialog.vue";
import DeliveriesTable from "../components/DeliveriesTable.vue";
import dayjs from 'dayjs';

export default {
  name: "Deliveries",
  components: { ProfilePicker, PeriodPicker, ExportDialog, DeliveriesTable },

  data() {
    return {
      period: "recent",
      computedPeriod: null,
    };
  },

  computed: {
    profileId: {
      get() {
        return this.$root.profileId;
      },
      set(newVal) {
        this.$root.profileId = newVal;
      }
    },

    startTime() {
      return this.computedPeriod.start ? dayjs(this.computedPeriod.start).startOf("day").toDate() : null;
    },

    endTime() {
      return this.computedPeriod.end ? dayjs(this.computedPeriod.end).add(1, "day").startOf("day").toDate() : null;
    }
  },

  methods: {
    openExport() {
      this.$refs.export.open(
        this.profileId,
        this.period === "recent" ? "today" : this.period,
      );
    }
  },


};
</script>

