<template>
  <div >
    <v-dialog
      v-model="dialog"
      width="400">
      
      <!-- List Item -->
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" v-on="on" dense> 
          <v-list-item-icon>
              <v-icon>mdi mdi-card-account-phone-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
              <v-list-item-title>Tech Support</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <!-- Pop Up Card -->
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 d-flex justify-space-between">
          24/7 Technical Support
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="zoom:1.18;">
          <br>
          <b>Phone Numbers</b><br>
          UK: 0207 255 6000<br>
          USA: 18332397907<br>
          <b>Email</b><br>
          <a href = "mailto: help@itstheflashpack.com" target="_blank">help@itstheflashpack.com</a>
        </v-card-text>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "TechSupportDialog",
  data () {
    return {
      dialog: false,
    }
  }
  
}
</script>