function matchWildcard(pattern, input) {
  return pattern == input || (pattern.endsWith("*") && input.startsWith(pattern.slice(0, -1)));
}

class Permissions {
  constructor(data) {
    this.statements = data.statements;
  }

  canPerform(action, resources) {
    if (typeof resources === "string") {
      resources = [resources];
    } else if (!Array.isArray(resources)) {
      resources = Object.entries(resources).map(([key, value]) => `${key}:${value}`);
    }

    for (let statement of this.statements) {
      for (let _action of statement.actions) {
        if (matchWildcard(_action, action)) {
          for (let _resource of statement.resources) {
            for (let resource of resources) {
              if (matchWildcard(_resource, resource))
                return true;
            }
          }
        }
      }
    }
    return false;
  }

  canPerformType(action, resourceType) {
    for (let statement of this.statements) {
      for (let _action of statement.actions) {
        if (matchWildcard(_action, action)) {
          for (let _resource of statement.resources) {
            let lastIndex = _resource.lastIndexOf(":");
            let _resourceType = lastIndex < 0 ? _resource : _resource.substring(0, lastIndex);
            if (matchWildcard(_resourceType, resourceType))
              return true;
          }
        }
      }
    }
    return false;
  }
}

export default Permissions;
