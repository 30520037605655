<template>
  <div class="fill-y d-flex align-center justify-center">
    <v-card flat color="transparent">
      <v-card-title class="justify-center">
        <span class="bolt black"></span>
        <span class="text-h2">Airtime</span>
      </v-card-title>

      <v-card-text class="text-center">
        <div class="py-4">
          <v-btn outlined large block :href="loginURL">
            <v-icon left>mdi-login</v-icon>
            Sign in
          </v-btn>
        </div>
        <div class="pt-4">
          <v-btn text small :href="staffLoginURL">
            Staff sign in
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Login',

    computed: {
      loginURL() {
        let redirectURL = window.location.origin;
        return this.$apiBaseURL + '/session/login?redirect_url=' + encodeURIComponent(redirectURL);
      },

      staffLoginURL() {
        let redirectURL = window.location.origin;
        return this.$apiBaseURL + '/session/login/staff?redirect_url=' + encodeURIComponent(redirectURL);
      }
    }
  }
</script>

<style scoped>
  .bolt {
    height: 5rem;
    width: 2.1rem;
  }
</style>