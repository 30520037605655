import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import Axios from 'axios';
import yaml from 'js-yaml';
import Permissions from './permissions';
import VCardHeader from './components/VCardHeader';

import dayjs from 'dayjs';
import dayjs_utc from 'dayjs/plugin/utc';
import dayjs_calendar from 'dayjs/plugin/calendar';
import dayjs_localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs_duration from 'dayjs/plugin/duration';
import dayjs_relativeTime from 'dayjs/plugin/relativeTime';
import dayjs_enGB from 'dayjs/locale/en-gb';

dayjs.extend(dayjs_utc);
dayjs.extend(dayjs_calendar);
dayjs.extend(dayjs_localizedFormat);
dayjs.extend(dayjs_duration);
dayjs.extend(dayjs_relativeTime);

let locales = [];
if (navigator.languages) locales = Array.from(navigator.languages);
else if (navigator.language) locales = [navigator.language];
locales.push('en-gb');

for (let locale of locales) {
  locale = locale.toLowerCase();
  if (locale === 'en' || locale === 'en-us') break;
  else if (locale === 'en-gb') {
    dayjs.locale(dayjs_enGB);
    break;
  }
}

Vue.config.productionTip = false;

Vue.prototype.$apiBaseURL =
  process.env.VUE_APP_API_BASE_URL || 'https://api.' + window.location.host;
Vue.prototype.$api = Axios.create({
  baseURL: Vue.prototype.$apiBaseURL,
});

Vue.prototype.$dayjs = dayjs;
Vue.prototype.$formatDateTime = (utc) => {
  return dayjs.utc(utc).local().calendar(null, {
    lastWeek: 'L LT',
    nextWeek: 'L LT',
    sameElse: 'L LT',
  });
};

Vue.component('v-card-header', VCardHeader);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),

  data: {
    // maybe use vuex or another store for these
    isLoading: true,
    token: null,
    reloadTokenPromise: null,
    user: null,
    permissions: null,

    profileId: null,
    profiles: null,
    loadProfilesPromise: null,
  },

  computed: {
    isLogin() {
      return this.$route.path === '/login';
    },
  },

  created() {
    this.$api.interceptors.response.use(null, (error) => {
      if (error.response?.status !== 401 || error.config._retry)
        return Promise.reject(error);

      console.log('RELOADING TOKEN');

      this.reloadTokenPromise ??= this.loadToken()
        .finally(() => (this.reloadTokenPromise = null))
        .catch((error) => Promise.reject(error));

      delete error.config.headers['Authorization'];
      error.config._retry = true;
      return this.reloadTokenPromise.then(() =>
        this.$api.request(error.config)
      );
    });

    this.initialLoad();
  },

  methods: {
    async initialLoad() {
      await this.loadToken();
      await this.loadUser();

      if (this.isLogin) this.$router.push('/');

      this.isLoading = false;
    },

    async loadToken() {
      try {
        let response = await Axios.post(
          this.$apiBaseURL + '/session/token',
          null,
          { withCredentials: true }
        );
        this.token = response.data;
        this.$api.defaults.headers.common['Authorization'] =
          'Bearer ' + this.token.access_token;
      } catch (err) {
        if (err.response && err.response.status === 401) {
          if (!this.isLogin) this.$router.push('/login');
          this.isLoading = false;
        }
        throw err;
      }
    },

    async loadUser() {
      let response = await this.$api.get('/users:current');
      this.user = response.data;
      this.permissions = new Permissions(this.user.permissions);
    },

    async loadProfiles() {
      let response = await this.$api.get('/profiles', {
        params: { page_size: 100, order_by: 'desc' },
      });
      this.profiles = response.data.items;
      let nextPageToken = response.data.next_page_token;
      if(nextPageToken) {
        let nextProfiles = await this.$api.get('/profiles', {
          params: { page_size: 100, order_by: 'desc', page_token: nextPageToken },
        });
        this.profiles = this.profiles.concat(nextProfiles.data.items)
      }
    },

    async getProfiles() {
      if (this.profiles === null) {
        this.loadProfilesPromise ??= this.loadProfiles().finally(
          () => (this.loadProfilesPromise = null)
        );
        await this.loadProfilesPromise;
      }
      return this.profiles;
    },

    async getProfile(id) {
      let profiles = await this.getProfiles();
      for (let profile of profiles) {
        if (profile.id === id) return profile;
      }
    },

    async getProfilesFromBucket() {
      try {
        const response = await Axios.get(
          'https://vh3wafodm9.execute-api.eu-west-2.amazonaws.com/v1/get-profiles'
        );
        return await response.data.filter((profile) => {
          return this.$root.permissions.canPerform("view_profile_editor", {profile});
        });
      } catch (err) {
        console.error('Error loading profiles', err);
        throw err;
      }
    },

    async getProfileFromBucket(profile) {
      try {
        const response = await Axios.get(
          `https://vh3wafodm9.execute-api.eu-west-2.amazonaws.com/v1/get-profile?profile=${profile}`
        );
        const parsedYaml = yaml.load(response.data);
        return parsedYaml;
      } catch (err) {
        console.error(`Error loading profile '${profile}'`, err);
        throw err;
      }
    },

    async updateProfile(configJson, files) {
      try {
        const configYaml = yaml.dump(configJson);
        const response = await Axios.post(
          'https://vh3wafodm9.execute-api.eu-west-2.amazonaws.com/v1/update-profile',
          {
            profile: this.$route.params.profileName,
            configYaml,
            files: files ? Object.keys(files) : [], // check if files is defined and return an empty array if it is not
          }
        );

        const presignedUrls = response.data.presigned_urls;

        for (const [fileName, presignedUrl] of Object.entries(presignedUrls)) {
          if (!files || !files[fileName]) continue; // skip if file is not provided
          const file = files[fileName];
          await Axios.put(presignedUrl, file, {
            headers: {
              'Content-Type': file.type,
            },
          });
        }

        return response;
      } catch (e) {
        console.error(
          `Error updating profile ${this.$route.params.profileName}`
        );
        throw e;
      }
    },

    async cloneProfile(sourceFolder, destinationFolder, profileConfig) {
      try {
        const configYaml = yaml.dump(profileConfig);
        const response = await Axios.post(
          'https://vh3wafodm9.execute-api.eu-west-2.amazonaws.com/v1/clone-profile',
          {
            sourceFolder: sourceFolder,
            destinationFolder: destinationFolder,
            configYaml: configYaml,
          }
        );

        return response;
      }
      catch (err) {
        console.error(`Error cloning profile '${sourceFolder}'`, err);
        throw err;
      }
    },

    isJDHack(profileId) {
      return profileId.startsWith("event.2024.nov.jdsports") && !this.user.username.startsWith("Google_");
    }
  },
}).$mount('#app');
