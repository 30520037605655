<template>
  <div>
    <template v-if="control.type === 'string'">
      <div v-if="control.type === 'string' && control.format === 'color'">
        <v-text-field :label="control.title" :value="variables[control.id]" @input="updateTextField(control.id, $event)"
          clearable @click:clear="clearColor(control.id)" :hint="control.description" persistent-hint>
          <template v-slot:prepend-inner>
            <v-menu v-model="colorMenus[control.id]" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <div :style="swatchStyle(variables[control.id])" v-on="on">
                  <v-icon v-if="variables[control.id] === null">mdi-pencil</v-icon>
                </div>
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker mode="hexa" :value="colorPickerValue(control.id)" flat
                    @update:color="updateColor(control.id, $event)" />
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
        </v-text-field>
      </div>

      <v-textarea v-else-if="control.paragraph" :label="control.title" v-model.lazy="variables[control.id]" :hint="control.description" persistent-hint />

      <v-text-field v-else :label="control.title" v-model.lazy="variables[control.id]" :hint="control.description" persistent-hint></v-text-field>
    </template>

    <v-text-field v-if="control.type === 'number'" type="number" :label="control.title" v-model.lazy.number="variables[control.id]" :hint="control.description" persistent-hint></v-text-field>

    <div v-if="control.type === 'file'">
      <FileInput :label="control.title" :accept="control.types.join(', ')" :controlId="control.id" :initialFile="files[control.id]"
        @file-changed="handleFileChange($event, control.id)" @file-removed="handleFileRemove(control.id)" :hint="control.description" />
    </div>

    <v-switch v-if="control.type === 'boolean'" :label="control.title" v-model.lazy="variables[control.id]" :hint="control.description" persistent-hint></v-switch>
  </div>
</template>


<script>
import { VSwitch, VTextField, } from 'vuetify/lib';
import { VMenu, VCard, VCardText, VColorPicker } from 'vuetify/lib';
import FileInput from './FileInput.vue'
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'CustomControl',
  components: {
    VSwitch,
    VTextField,
    // VFileInput,
    VMenu,
    VCard,
    VCardText,
    VColorPicker,
    FileInput
  },
  props: {
    control: {
      type: Object,
      required: true,
    },
    variables: {
      type: Object,
      required: true,
    },
    files: {
      type: Object,
      required: false,
    },
    scheduleIndex: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      colorMenus: {},
      pickerUpdate: {},
      filePreviews: {},
    };
  },
  created() {
    if (this.variables[this.control.id] === undefined) {
      this.$set(this.variables, this.control.id, this.control.type === "boolean" ? false : null);
    }
  },
  computed: {
    imagePreview() {
      return (controlId) => {
        if (this.filePreviews[controlId]) {
          return this.filePreviews[controlId];
        }
        return null;
      };
    },
    colorPickerValue() {
      return (controlId) => {
        return this.variables[controlId] || '';
      };
    },
  },
  methods: {
    handleFileChange(file, controlId) {
      if (file) {
        let extension = file.name.split('.').pop();
        let fileName = `assets/${uuidv4()}.${extension}`;
        this.files[controlId] = fileName;
        const newFile = new File([file], `${controlId}.${extension}`, { type: file.type });
        this.$emit('raw-files-changed', { file: newFile, fileName: fileName });
      }
    },
    handleFileRemove(controlId) {
      this.$delete(this.files, controlId);
      this.$emit('raw-files-changed', { file: null, controlId: controlId });
    },
    updateTextField(controlId, value) {
      this.variables[controlId] = value;
    },
    updateColor(controlId, color) {
      if (this.pickerUpdate[controlId]) {
        this.$set(this.pickerUpdate, controlId, false);
      } else {
        this.variables[controlId] = color.hexa;
      }
    },
    clearColor(controlId) {
      this.variables[controlId] = null;
      this.colorMenus[controlId] = false;
      this.$set(this.pickerUpdate, controlId, true);
    },
    swatchStyle(color) {
      return {
        backgroundColor: color ? color : '#f5f5f5',
        cursor: "pointer",
        height: "24px",
        width: "24px",
        borderRadius: "4px",
        transition: "border-radius 200ms ease-in-out",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
    },
  },
};
</script>