<template>
  <span>
    An error occurred<template v-if="errorDetail">: {{errorDetail}}</template>
  </span>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: {
    error: [Boolean, Error, String]
  },

  computed: {
    errorDetail() {
      let error = this.error?.response?.data?.detail || this.error;
      if (typeof error === "string")
        return error;
      return null;
    }
  },
}
</script>

<style>

</style>