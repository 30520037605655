<template>
  <div class="mb-4">
    <div class="grey--text mb-1">{{label}}</div>
    <div class="grey--text text--darken-4">
      <template v-if="value">{{value}}</template>
      <slot v-else-if="$slots.default" />
      <template v-else>-</template>
    </div>
  </div>
</template>

<script>
export default {
  name: "LabelValue",
  props: {
    label: String,
    value: String
  }
}
</script>
