<template>
  <div v-if="promiseLoading || loading" class="text-center my-4">
    <v-progress-circular indeterminate width="2" />
  </div>
  <p v-else-if="promiseError || error" class="error--text text-center my-4">
    <error-message :error="promiseError || error" />
  </p>
  <p v-else-if="!hasData" class="grey--text text-center my-4">Nothing to display</p>
  <div v-else>
    <slot />
  </div>
</template>

<script>
import ErrorMessage from './ErrorMessage.vue'

export default {
  name: "AsyncState",
  components: {ErrorMessage},

  props: {
    promise: Promise,
    loading: Boolean,
    error: [Boolean, Error, String],
    hasData: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      promiseLoading: false,
      promiseError: null
    }
  },

  watch: {
    promise: {
      handler(newVal) {
        this.promiseError = null;
        this.promiseLoading = false;

        if (newVal) {
          this.promiseLoading = true;
          newVal.then(() => {
            if (newVal === this.promise)
              this.promiseLoading = false;
          }, (err) => {
            if (newVal === this.promise) {
              this.promiseLoading = false;
              this.promiseError = err;
            }
          })
        }
      },
      immediate: true
    }
  }
}
</script>