<template>
  <v-alert v-if="$slots.default" :type="type" text>
    <slot />
  </v-alert>
  <v-alert v-else-if="error" type="error" text>
    <error-message :error="error" />
  </v-alert>
  <v-alert v-else-if="$route.params.statusAlert" type="success" text>
    {{$route.params.statusAlert}}
  </v-alert>
</template>

<script>
import ErrorMessage from "./ErrorMessage"

export default {
  name: "StatusAlert",
  components: {ErrorMessage},

  props: {
    error: [Boolean, Error, String],
    type: String
  }
}
</script>