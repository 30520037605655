<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <h2 class="text-h5">
        <router-link :to="{name: 'data'}">Guest Data</router-link>
        <v-icon class="mx-2">mdi-chevron-right</v-icon>
        <span>{{id}}</span>
      </h2>
      <div>
        <v-btn v-if="canErase" text color="error" @click="erase">
          <v-icon left>mdi-eraser</v-icon>
          Erase
        </v-btn>
      </div>
    </div>

    <!-- Alerts -->
    <status-alert v-if="eraseSuccess" type="success">Data erased successfully</status-alert>
    <status-alert :error="eraseError" />

    <!-- Details -->
    <v-card>
      <v-card-header>
        <v-card-title class="pa-0">Details</v-card-title>
      </v-card-header>
      <v-card-text>
        <async-state :promise="submissionPromise">
          <template v-slot:default>
          <v-row class="row-divided">
            <v-col cols="4">
              <label-value label="ID" :value="submission.id" />
              <label-value label="Profile" :value="submission.profile_id" />
            </v-col>
            <v-col cols="4">
              <label-value label="Status">
                <status-chip :status="submission.status" small />
              </label-value>
            </v-col>
            <v-col cols="4">
              <label-value label="Created time" :value="$formatDateTime(submission.create_time)" />
              <label-value label="Synced time" :value="$formatDateTime(submission.sync_time)" />
            </v-col>
          </v-row>
          </template>
        </async-state>
      </v-card-text>
    </v-card>

    <!-- Attributes -->
    <v-card class="mt-6">
      <v-card-header>
        <v-card-title class="pa-0">
          <span>Attributes</span>
          <span v-if="submission" class="grey--text ml-2">({{attributesLength}})</span>
        </v-card-title>
      </v-card-header>
      <v-card-text>
        <async-state :promise="submissionPromise" :has-data="Boolean(attributesLength)">
          <template #default>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Attribute name</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, key) in submission.attributes" :key="key">
                  <td>{{key}}</td>
                  <td>{{value}}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </template>
        </async-state>
      </v-card-text>
    </v-card>

    <!-- Content -->
    <v-card class="mt-6">
      <v-card-header>
        <v-card-title class="pa-0">
          <span>Linked Content</span>
          <span v-if="submission" class="grey--text ml-2">({{submission.deliverables_count}})</span>
        </v-card-title>
      </v-card-header>
      <v-card-text>
        <async-state :promise="deliverablesPromise" :has-data="deliverables && deliverables.length > 0">
          <v-simple-table>
            <thead>
              <tr>
                <th>Thumbnail</th>
                <th>Created time</th>
                <th><v-icon small title="Linked data">mdi-database</v-icon></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="deliverable in deliverables" :key="deliverable.id">
                <td>
                  <div class="pa-2">
                    <deliverable-thumbnail :deliverable="deliverable" :max-height="64" :max-width="150" />
                  </div>
                </td>
                <td>
                  <router-link :to="{name: 'content-detail', params: {id: deliverable.id}}">
                    {{$formatDateTime(deliverable.create_time)}}
                  </router-link>
                </td>
                <td>
                  <template v-if="deliverable.submissions_count">{{deliverable.submissions_count}}</template>
                  <template v-else>-</template>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </async-state>
      
      </v-card-text>
    </v-card>

    <confirm-dialog ref="confirm" />

  </v-container>
</template>

<script>
import AsyncState from "../components/AsyncState"
import StatusChip from "../components/StatusChip"
import LabelValue from "../components/LabelValue"
import DeliverableThumbnail from "../components/DeliverableThumbnail"
import StatusAlert from "../components/StatusAlert"
import ConfirmDialog from "../components/ConfirmDialog"

export default {
  name: "DataDetail",
  components: {AsyncState, StatusChip, LabelValue, DeliverableThumbnail, StatusAlert, ConfirmDialog},

  props: {
    id: String,
  },

  data() {
    return {
      submission: null,
      submissionPromise: null,

      deliverables: null,
      deliverablesPromise: null,

      eraseSuccess: false,
      eraseError: null
    };
  },

  computed: {
    attributesLength() {
      if (!this.submission)
        return null;
      return Object.keys(this.submission.attributes).length;
    },

    canErase() {
      return this.$root.permissions.canPerform("erase_submission", {
        submission: this.id,
        profile: this.submission?.profile_id
      });
    }
  },

  created() {
    this.submissionPromise = this.loadSubmission();
    this.deliverablesPromise = this.loadDeliverables();
  },

  methods: {
    async loadSubmission() {
      this.submission = null;
      let response = await this.$api.get("/submissions/" + this.id);
      this.submission = response.data;
      this.$root.profileId = response.data.profile_id;
    },

    async loadDeliverables() {
      this.deliverables = null;
      let response = await this.$api.get("/deliverables?submission_id=" + this.id);
      this.deliverables = response.data.items;
    },

    async erase() {
      try {
        if (await this.$refs.confirm.present({
          message: "Are you sure you want to erase personal data?",
          confirm: "Erase",
          color: "error",
          callback: async () => {
            let response = await this.$api.post(`/submissions/${this.id}:erase`);
            this.submission = response.data;
          }
        })) {
          this.eraseSuccess = true;
          this.eraseError = null;
        }
      } catch(err) {
        this.eraseSuccess = false;
        this.eraseError = err;
      }
    }
  }
};
</script>
