<template>
  <v-dialog v-model="showDialog" max-width="600px">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span>Send Test Email</span>
        <v-btn text icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>
          A tool to send the HTML email template to different addresses.
        </p>
        <div v-if="isLoading" class="d-flex align-center justify-center" style="min-height: 100px;">
          <v-progress-circular indeterminate size="30" width="3"/>
        </div>
        <div v-else>
          <v-text-field label="Email" v-model="email"></v-text-field>
          <div class="d-flex justify-space-between">
            <div class="red--text">
              <span v-if="emailError">Please check your email address.</span> 
              <span v-else-if="error">Failed to send email, please try again later.</span> 
            </div>
            <v-btn
              color="primary"
              :disabled="!email"
              @click="send">
              Send
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "ExportDialog",
  props: {
    profileId: String,
  },

  data() {
    return {
      showDialog: false,
      panel: 0,
      email: `${this.$root.user.email}`,
      emailError: false,
      isLoading: false,
      error: false,
      templateId: null
    }
  },

  methods: {
    open(templateId) {
      this.isLoading = false;
      this.showDialog = true;
      this.templateId = templateId;
    },

    async send() {
      if (!this.validateEmail(this.email)) {
        this.emailError = true;
        return;
      }
      this.emailError = false;
      this.isLoading = true;

      try {
        await this.$api.post("/deliveries", {
          profile_id: this.profileId,
          destination: this.email,
          template_id: this.templateId
        })
        this.showDialog = false;
        this.isLoading = false;
        this.error = false;
      } catch (error) {
        this.isLoading = false;
        this.error = true;
      }
    },

    close() {
      this.showDialog = false;
    },

    validateEmail(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    },
  }
};
</script>